/* eslint-disable max-len */
// context part
import {
  createContext, Dispatch, ReactNode, useMemo, useReducer,
} from 'react';

interface EchangesProps {
  children: ReactNode
}

type IState = {
  user: any
  loading: boolean
  acceptedPolicy: boolean
  channels: any[]
}

export type ExchangesContextType = {
  state: IState
  dispatch: Dispatch<any>
}

const initialContext = {
  state: {
    user: null,
    loading: true,
    acceptedPolicy: false,
    channels: [],
  },
  dispatch: () => {},
};

export const ExchangesContext = createContext<ExchangesContextType>(initialContext);

const reducer = (store, action) => {
  switch (action.type) {
    case 'getProfileQuery':
      return { ...store, state: { ...store.state, ...action.payload } };
    case 'SET_STATE':
      return { ...store, state: { ...store.state, ...action.payload } };
    case 'GET_USER':
      return { ...store, state: { ...store.state, ...action.payload } };
    case 'NEW_UNREAD': {
      const newChannels = store.state.channels.map((el) => (el.id === action.payload.channelId
        ? { ...el, userRead: { unreadUserMessage: (el.userRead.unreadUserMessage || 0) + 1 } } : el));
      return { ...store, state: { ...store.state, channels: newChannels } };
    }
    case 'RESET_UNREAD': {
      const newChannels = store.state.channels.map((el) => (el.id === action.payload.channelId
        ? { ...el, userRead: { ...el.userRead, unreadUserMessage: 0, hasClicked: true } } : el));
      return { ...store, state: { ...store.state, channels: newChannels } };
    }
    default:
      break;
  }
  return { ...store };
};

const ExchangesProvider = ({ children }: EchangesProps) => {
  const [state, dispatch] = useReducer(reducer, initialContext);
  const store = useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <ExchangesContext.Provider value={{
      ...store,
      state: {
        ...store.state,
      },
    }}
    >
      {children}
    </ExchangesContext.Provider>
  );
};

export default ExchangesProvider;
