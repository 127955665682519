import { notification } from 'antd';
import { queryListChannels } from './exchanges';

/* eslint-disable import/prefer-default-export */
export const getListChannelsAct = async (dispatch, currentChannelId) => {
  try {
    const channels = await queryListChannels();
    dispatch({
      type: 'SET_STATE',
      payload: {
        channels: channels?.data?.listChannels.map((el) => (el.id === currentChannelId ? ({ ...el, userRead: { ...el.userRead, hasClicked: true } }) : el)),
      },
    });
  } catch (error) {
    console.error({ error });
    notification.error({
      message: 'Veuillez nous excuser, une erreur est survenue.',
      description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
    });
  }
};
