import {
  forwardRef, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  RichUtils, EditorState, Modifier, SelectionState, CompositeDecorator, convertToRaw, convertFromRaw,
} from 'draft-js';
import DraftEditor from '@draft-js-plugins/editor';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import { Map } from 'immutable';
import { notification } from 'antd';
import SC from './style';
import { BlockStyleControls, InlineStyleControls, Util } from './StyleControls';
import {
  mutationEditMessage, mutationEditReply, mutationMessage, mutationReply,
} from 'containers/Exchanges/actions/exchanges';
import UrlInput from 'components/UrlInput';
import {
  findLinkEntities,
  formatMessageBlocks,
  getCurrentBlock,
  getLinkKeyInfos, regexpEmail, replaceTextEntity, selectionURLInfosFormatted,
} from './utils';
import { IShowUrlInput, IUrlEditChoice } from 'components/Exchange/types';
import ViewedUrl from 'components/UrlStyles/ViewedUrl';
import ManageUrl from 'components/UrlStyles/ManageUrl';
import keyBindingFn from './hooks/keyBindingFn';
import Footer from './Footer';
import useDebounce from 'hooks/useDebounce';
import { addNewBlockAt, blockRendererFn } from './CustomBlock';

defaultTheme.emojiSelect = 'RichEditor-emoji';
defaultTheme.emojiSelectButton = 'RichEditor-styleButton';
defaultTheme.emojiSelectButtonPressed = 'RichEditor-activeButton';
defaultTheme.emojiSelectPopover += ' emojiSelectPopoverCustom';
defaultTheme.emojiSuggestions += ' emojiSuggestionsCustom';

const emojiPlugin = createEmojiPlugin({
  theme: defaultTheme,
  selectButtonContent:
  <Util type="emoji" />,
});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const emojiPluginReply = createEmojiPlugin({
  theme: defaultTheme,
  selectButtonContent:
  <Util type="emoji" />,
});

const { EmojiSuggestions: EmojiSR, EmojiSelect: EmojiSelectR } = emojiPluginReply;

interface IEditor {
  readOnly?: boolean
  currentChannel: any
  setUrlValue:any
  setShowURLInput: (showUrlInput: IShowUrlInput) => void
  currentReply: any
  showURLInput: IShowUrlInput
  urlValue: any
  focusEditor: any
  mode: IShowUrlInput
  setURLInfos: any
  URLInfos: any
  overrideEditorState?: any
  setEditionMode?: any
  message?: any
  handleSubmitMessage: any
}

// eslint-disable-next-line react/display-name
const Editor = forwardRef(({
  readOnly = false, currentChannel, setUrlValue, setShowURLInput,
  currentReply, showURLInput, urlValue, overrideEditorState,
  focusEditor, mode, setURLInfos, URLInfos, setEditionMode, message, handleSubmitMessage,
}: IEditor, editorRef: any) => {
  const [editorState, setEditorState] = useState(overrideEditorState || EditorState.createEmpty());
  const [disabledForm, setDisabledForm] = useState(true);
  const [messageLen, setMessageLen] = useState(0);

  const plainText = overrideEditorState?.getCurrentContent().getPlainText();
  const overrideUrlsPreview = plainText?.match(regexpEmail);

  const [urlsPreview, setUrlsPreview] = useState(overrideUrlsPreview || []);

  const debouncedValue = useDebounce<number>(messageLen, 200);

  const onAddCustomBlock = (og, newEditorState, url) => {
    const selection = newEditorState.getSelection();
    const editorStateBis = addNewBlockAt(
      newEditorState,
      selection.getAnchorKey(),
      'MyCustomBlock',
      Map({
        associateKey: getCurrentBlock(newEditorState).getKey(),
        title: og.title,
        description: og.description,
        image: og['og:image'],
        url,
      }),
    );
    // setEditorState(editorStateBis);
    return editorStateBis;
    // setTimeout(() => focusEditor(), 0);
  };

  useEffect(() => {
    let newEditorState = editorState;
    const value = newEditorState.getCurrentContent().getPlainText();
    const urls = value.match(regexpEmail);
    if (JSON.stringify(urls) === JSON.stringify(urlsPreview)) {
      return undefined;
    }
    const raw = convertToRaw(newEditorState.getCurrentContent());
    const newContent = convertFromRaw({
      ...raw,
      blocks: raw.blocks.filter((element) => {
        const tt = element.type === 'MyCustomBlock' && !urls?.includes(element.data.url);

        return !tt;
      }),
    });
    if (newContent !== newEditorState.getCurrentContent()) {
      newEditorState = EditorState.push(newEditorState, newContent, 'remove-block');

      const currentSelection = editorState.getSelection();
      newEditorState = EditorState.forceSelection(newEditorState, currentSelection);
      setEditorState(newEditorState);
    }

    urls?.forEach(async (url) => {
      const block = getCurrentBlock(newEditorState);

      const blockKey = block.getKey();
      if (urlsPreview?.includes(url)) return undefined;
      const start = value.indexOf(url);
      const end = start + url.length;
      const contentStateWithEntity = newEditorState
        .getCurrentContent()
        .createEntity('LINK', 'MUTABLE', { url, text: url });

      const selection = selectionURLInfosFormatted({ blockKey, start, end });

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const contentStateWithLink = Modifier?.applyEntity(
        contentStateWithEntity,
        new SelectionState(selection),
        entityKey,
      );
      newEditorState = EditorState.set(newEditorState, {
        currentContent: contentStateWithLink,
      });

      const fetchMetadata = async () => {
        const res = await fetch('/api/metadata', {
          method: 'POST',
          body: url,
        });
        const data = await res.json();
        newEditorState = await onAddCustomBlock(JSON.parse(data), newEditorState, url);
        return newEditorState;
      };
      newEditorState = await fetchMetadata();
      const currentSelection = editorState.getSelection();
      newEditorState = EditorState.forceSelection(newEditorState, currentSelection);
      setEditorState(newEditorState);
    });

    // setEditorState(stateWithContentAndSelection);

    setUrlsPreview(urls);
  }, [debouncedValue]);

  const urlRef = useRef(null);

  const maxLen = 2000;

  const focusUrl = useCallback(() => {
    if (urlRef?.current) {
      urlRef.current.focus();
    }
  }, [urlRef]);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: (props) => (
        <ViewedUrl
          {...props}
          onClick={(infos) => {
            console.log({ infos });
            setURLInfos({ ...infos, mode });
          }}
        />
      ),
    },
  ]);

  useEffect(() => {
    if (overrideEditorState) {
      const decoratedEditor = EditorState.set(overrideEditorState, { decorator });
      setEditorState(decoratedEditor);
    }

    const decoratedEditor = EditorState.set(editorState, { decorator });
    setEditorState(decoratedEditor);
  }, [overrideEditorState]);

  const removeLink = (e) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    const entitySelection = new SelectionState(selectionURLInfosFormatted(URLInfos));
    const contentWithRemovedLink = Modifier.applyEntity(URLInfos.contentState, entitySelection, null);

    setURLInfos(null);
    setEditorState((pS) => {
      const newEdit = EditorState.set(pS, { decorator });
      return EditorState.set(
        newEdit, { currentContent: contentWithRemovedLink },
      );
    });
  };

  const saveLink = (e) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();

    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url: urlValue.link, text: urlValue.text });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentState = !URLInfos
      ? replaceTextEntity(editorState, editorState.getSelection(), urlValue, entityKey)
      : replaceTextEntity(
        editorState,
        new SelectionState(selectionURLInfosFormatted(URLInfos)),
        urlValue,
        URLInfos.entityKey,
      );

    let newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    newEditorState = EditorState.set(newEditorState, { decorator });

    setEditorState(
      EditorState.push(newEditorState, contentState, 'insert-characters'),
    );

    setShowURLInput(null);
    setURLInfos(null);
    setUrlValue({ text: '', link: '' });

    setTimeout(() => focusEditor(), 0);
  };

  const confirmLink = async (e, choice: IUrlEditChoice, saveMode = false) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();

    switch (choice) {
      case 'delete': {
        removeLink(e);
        break;
      }
      case 'back': {
        setURLInfos(null);
        setShowURLInput(null);
        break;
      }
      case 'edit': {
        if (saveMode) return saveLink(e);
        const entitySelection = new SelectionState(selectionURLInfosFormatted(URLInfos));
        const contentWithRemovedLink = Modifier.applyEntity(URLInfos.contentState, entitySelection, null);
        setEditorState((pS) => {
          const newEdit = EditorState.set(pS, { decorator });
          return EditorState.set(
            newEdit, { currentContent: contentWithRemovedLink },
          );
        });
        setUrlValue({ link: URLInfos.url, text: URLInfos.decoratedText });
        setShowURLInput(mode);
        break;
      }
      default:
        break;
    }
    setTimeout(() => focusEditor(), 0);
    return null;
  };

  const promptForLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setUrlValue(getLinkKeyInfos(editorState));
      setShowURLInput(mode);
      setTimeout(() => focusUrl(), 0);
    } else {
      setUrlValue({ text: '', link: '' });
      setShowURLInput(mode);
    }
  };

  const handleSubmit = async (e, saved = true) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    const { newMessageFormatted, raw } = formatMessageBlocks(editorState);
    if (!newMessageFormatted?.[0]?.text) return null;

    const content = JSON.stringify({ ...raw, blocks: newMessageFormatted });
    try {
      switch (mode) {
        case 'create': {
          handleSubmitMessage(mode, content);
          setEditorState(EditorState.createEmpty());

          break;
        }
        case 'reply': {
          handleSubmitMessage(mode, content, currentReply.id);
          setEditorState(EditorState.createEmpty());

          break;
        }
        case 'edit': {
          if (!saved) return setEditionMode(false);
          handleSubmitMessage(mode, content, message.id);
          setEditorState(EditorState.createEmpty());
          setEditionMode(false);

          await mutationEditMessage({
            channelId: message.channelId,
            content,
            id: message.id,
          });
          break;
        }
        case 'edit-reply': {
          if (!saved) return setEditionMode(false);
          handleSubmitMessage(mode, content, message.id);
          setEditorState(EditorState.createEmpty());
          setEditionMode(false);

          await mutationEditReply({
            channelId: message.channelId,
            content,
            id: message.id,
          });
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log({ error, mode });
      notification.error({
        message: 'Veuillez nous excuser, une erreur est survenue.',
        description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
      });
    }
    return null;
  };

  const onChange = async (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const value = currentContent.getPlainText();
    setMessageLen(value.length);
    if (value.length > 0 && value.length <= maxLen) setDisabledForm(false);
    else setDisabledForm(true);
    setEditorState(newEditorState);
  };

  const toggleBlockType = (blockType) => {
    if (blockType === 'LINK') {
      promptForLink();
    } else {
      onChange(RichUtils.toggleBlockType(editorState, blockType));
    }
  };

  const toggleInlineStyle = (inlineStyle) => onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));

  let className = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  const handleKeyCommand = (command: string) => {
    switch (command) {
      case 'split-block': {
        handleSubmit({});
        return 'handled';
      }
      case 'handled-split-block': {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.splitBlock(currentContent, selection);
        setEditorState(EditorState.push(editorState, textWithEntity, 'split-block'));
        return 'handled';
      }
      default:
        return 'not-handled';
    }
  };

  const onSubmit = (e) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();

    if (!disabledForm) handleSubmit(e);
  };

  const onClosePreview = (e, p) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    const data = p.block.getData().toJS();
    const raw = convertToRaw(editorState.getCurrentContent());
    const newContent = convertFromRaw({
      ...raw,
      blocks: raw.blocks.filter((el) => el.data.url !== data.url),
    });

    const blockMap = newContent.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    const newEditorState = EditorState.push(editorState, newContent, 'remove-block');
    setEditorState(EditorState.forceSelection(newEditorState, selection));
  };

  const onClickPreview = ({ url }) => {
    window.open(url);
  };

  return (
    <SC.DivEditor>

      {URLInfos?.mode === mode && (
        <ManageUrl confirmLink={confirmLink} data={URLInfos} />
      )}

      {showURLInput === mode && (
        <UrlInput
          ref={urlRef}
          confirmLink={confirmLink}
          setUrlValue={setUrlValue}
          urlValue={urlValue}
          setShowURLInput={setShowURLInput}
        />
      )}

      <form css={SC.form} action="sendMessage" onSubmit={onSubmit}>
        <div css={SC.editorHead}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
          {mode === 'reply' ? <EmojiSR /> : <EmojiSuggestions />}
          {mode === 'reply' ? <EmojiSelectR closeOnEmojiSelect /> : (
            <EmojiSelect
              closeOnEmojiSelect
            />
          )}
        </div>

        <div className={className} onClick={focusEditor} role="presentation">
          <DraftEditor
            ref={editorRef}
            editorState={editorState}
            onChange={onChange}
            placeholder="Écrivez votre message ici..."
            keyBindingFn={(e) => keyBindingFn(e, editorState, setEditorState)}
            handleKeyCommand={handleKeyCommand}
            plugins={[mode === 'reply' ? emojiPluginReply : emojiPlugin]}
            blockRendererFn={(c) => blockRendererFn(c, onClosePreview, onClickPreview)}
          />
        </div>

        <Footer
          disabledForm={disabledForm}
          handleSubmit={handleSubmit}
          maxLen={maxLen}
          messageLen={messageLen}
          readOnly={readOnly}
        />

      </form>
    </SC.DivEditor>
  );
});

export default Editor;
