import SC from './style';
import { SvgLogoSmallLoader } from 'components/Svgs';

interface PlaceholderProps {
}

const Placeholder: React.FC<PlaceholderProps> = () => (
  <div css={SC.echangerWrap}>
    <div css={SC.loadingPage}>
      <SvgLogoSmallLoader />
    </div>
  </div>
);

export default Placeholder;
