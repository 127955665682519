/* eslint-disable react/display-name */
import {
  forwardRef, useRef, useState,
} from 'react';
import Link from 'next/link';
import { SvgCadenas, SvgCadenasClose, SvgPolygone } from 'components/Svgs';
import SC from './style';
import { IChannels } from 'domain/exchanges';
import useAuth from 'hooks/useAuth';
import BulleExchanges from 'components/BulleExchanges';
import useDatas from 'hooks/useDatas';
import useExchanges from 'containers/Exchanges/hooks/useExchanges';

interface ChannelsProps {
  currentChannel: any
  formattedChannels: any
}

const Channels = forwardRef((props: ChannelsProps, itemsRef: any) => {
  const {
    currentChannel,
    formattedChannels,
  } = props;
  const [hideChannels, setHideChannels] = useState([]);
  const { isSubscribed } = useAuth();
  const countRef = useRef(0);
  let indexCategoryStart = null;
  let indexChannelStart = null;

  const { state: { user: profile } } = useExchanges();
  const { user } = useAuth();
  const { baughtTraining, trainingList } = useDatas();

  Object.entries(formattedChannels).find((el: unknown, j) => el[1].find((e, ind) => {
    if (e.userRead.unreadUserMessage && !e.userRead.hasClicked) {
      indexCategoryStart = j;
      indexChannelStart = ind;
      return true;
    }
    return false;
  }));

  // console.log({ indexCategoryStart, indexChannelStart }, Object.entries(formattedChannels));

  const buildLink = (channel) => {
    let url = `/echanges?channel=${channel.id}`;
    if (channel?.userRead?.lastUserRead && channel?.userRead?.unreadUserMessage > 0) {
      url += `&messageId=${encodeURIComponent(channel?.userRead?.lastUserRead)}`;
    }
    return url;
  };

  const hasToDisplayChannel = (array) => {
    let len = 0;
    if (array[0] === 'Se Former') {
      array[1].forEach((element) => {
        if (baughtTraining?.find((b) => b.id === element.trainingHnaId)) len += 1;
      });
      return !!len;
    } return true;
  };

  const hasAccess = (el) => {
    if (el.category === 'Se Former') {
      const isFormateur = trainingList?.find(({ id }) => id === el.trainingHnaId)
        ?.coaches?.find(({ id }) => id === user?.id);

      return baughtTraining?.find((b) => b.id === el.trainingHnaId)
      || profile?.role?.includes('moderator')
      || isFormateur !== undefined;
    }
    return true;
  };

  return (
    <>
      {Object.entries(formattedChannels).map((element, index) => hasToDisplayChannel(element) && (
      <div
        key={element[0]}
        css={SC.blockChannels}
      >
        <div
          role="presentation"
          css={SC.blockTitle}
          onClick={() => {
            setHideChannels((pS) => {
              if (pS.includes(index)) return pS.filter((c) => c !== index);
              return [...pS, index];
            });
          }}
        >
          <div css={SC.polygone}>
            <SvgPolygone hide={hideChannels.includes(index)} />
          </div>
          <SC.PChannel
            isCategory
            hideChannels={hideChannels.includes(index)}
          >
            {element[0]}
          </SC.PChannel>
        </div>

        <SC.DivChannels
          hide={hideChannels.includes(index)}
        >
          {(element[1] as IChannels[])?.map((el, i) => hasAccess(el) && (
          <Link key={el.id} href={buildLink(el)} shallow passHref legacyBehavior>
            <SC.AChannel
              currentChannel={el.id === currentChannel?.id}
              news={el.userRead.unreadUserMessage}
            >
              {el.premium ? (
                <div css={SC.cadenas}>
                  {isSubscribed || hasAccess(el) ? (
                    <SvgCadenas />
                  ) : (
                    <SvgCadenasClose />
                  )}
                </div>
              ) : <div css={SC.cadenas} />}
              <SC.PChannel
                title={el.name}
                currentChannel={el.id === currentChannel?.id}
                key={el.id}
              >
                {el.name}
              </SC.PChannel>
              {el.userRead.unreadUserMessage && !el.userRead.hasClicked
                ? (
                  <div
                    ref={(r) => {
                      if (r) {
                        if (index === indexCategoryStart && i === indexChannelStart) {
                          itemsRef.current = [];
                          countRef.current = 0;
                        }
                        itemsRef.current[countRef.current] = r;
                        countRef.current += 1;
                      }
                    }}
                  >
                    <BulleExchanges
                      count={el.userRead.unreadUserMessage || 0}
                    />
                  </div>
                ) : null}
            </SC.AChannel>
          </Link>
          ))}
        </SC.DivChannels>
      </div>
      ))}
    </>
  );
});

export default Channels;
