/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      id
      name
      email
      picture
      role
      privacy
      createdAt
      updatedAt
      likesCount
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels {
    listChannels {
      id
      categoryName
      name
      type
      category
      premium
      createdAt
      messageCount
      userRead {
        lastUserRead
        unreadUserMessage
      }
      trainingHnaId
    }
  }
`;
export const listMessageByChannel = /* GraphQL */ `
  query ListMessageByChannel(
    $channelId: ID!
    $limit: Int!
    $sortDirection: DirectionType!
    $nextToken: String
  ) {
    listMessageByChannel(
      channelId: $channelId
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      messages {
        id
        channelId
        content
        createdAt
        updatedAt
        profile {
          id
          name
          email
          picture
          role
          privacy
          createdAt
          updatedAt
          likesCount
        }
        replies
        type
        likesCount
        liked
      }
      nextToken
    }
  }
`;
export const listReplyByMessage = /* GraphQL */ `
  query ListReplyByMessage(
    $messageId: ID!
    $limit: Int!
    $sortDirection: DirectionType!
    $nextToken: String
  ) {
    listReplyByMessage(
      messageId: $messageId
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      replies {
        id
        channelId
        content
        createdAt
        updatedAt
        profile {
          id
          name
          email
          picture
          role
          privacy
          createdAt
          updatedAt
          likesCount
        }
        inReplyToMessage
        type
        likesCount
        liked
      }
      nextToken
    }
  }
`;
