import styled, { css } from 'styled-components';

const DivEditor = styled.div`
  /* position: absolute; */
  width: 99%;
  max-height: 50vh;
  border-radius: 16px;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 30%);
  background-color: #fff;
`;

const editorHead = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.5vh;
  background-color: #eee;
  padding-left: 1vw;
`;

const form = css`
  display: flex;
  flex-direction: column;
`;

const sendButton = css`
  height: 2.96vh;
  width: 2.656vw;
  min-width: 37px;
  min-height: 23px;
  padding: 0;
  margin: 0 2vw 0.5vh 0;
`;

const divButton = css`
  display: flex;
`;

const helpSend = css`
  margin: 0 2vw 0.5vh auto;
  font-family: var(--font-family-sans);
  font-size: var(--size-14);
  font-style: italic;
  color: var(--color-black);
  display: inline-flex;
  align-items: center;
`;

const editionSubmit = css`
  margin: 0 2vw 0.5vh 0;
`;

const buttonBack = css`
  margin-right: 2vw;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
`;
const buttonSubmit = css`
  margin-right: 2vw;
  background-color: var(--color-blue-violet);
  border-radius: 4px;
  color: white;
`;

const utilSvg = css`
  height: 1.5vh;
  position: absolute;
  display: flex;
`;

const maxLen = css`
  position: absolute;
  right: 2vw;
  bottom: 4vh;
  color: red;
  font-size: var(--size-18);
  font-family: var(--font-family-sans);
  font-weight: 600;
`;

const linkiky = css`
  text-decoration: underline;
  color: rgb(59, 89, 152);
  cursor: pointer;
`;

export default {
  DivEditor,
  linkiky,
  maxLen,
  editorHead,
  divButton,
  utilSvg,
  form,
  helpSend,
  editionSubmit,
  buttonBack,
  buttonSubmit,
  sendButton,
};
