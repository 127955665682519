import styled, { css } from 'styled-components';

const PChannel = styled.p<any>`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  font-weight:  ${({ isCategory }) => (isCategory ? '600' : '300')};
  line-height: 1.86;
  text-align: left;
  cursor: pointer;
  color: var(--color-black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
`;

const blockChannels = css`
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
`;

const polygone = css`
  width: 0.573vw;
  height: 0.9259vh;
  position: relative;
  margin-right: 0.5vw;
`;

const cadenas = css`
  width: 0.781vw;
  min-width: 0.781vw;
  height: 1.3vh;
  min-height: 1.3vh;
  position: relative;
  margin-right: 0.5vw;
`;

const blockTitle = css`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  padding-left: 1.771vw;
`;

const AChannel = styled.a<any>`
  position: relative;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  padding-left: 1.58vw;
  background-color: ${({ currentChannel }) => (currentChannel ? 'rgb(0 0 0 / 11%)' : 'initial')};
  ${({ isCategory, currentChannel }) => !isCategory && !currentChannel && css`
    &:hover {
      color: white;
      background-color: rgb(0 0 0 / 11%);
    }
  `}
`;

const DivChannels = styled.div<any>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

export default {
  PChannel,
  cadenas,
  AChannel,
  blockChannels,
  polygone,
  blockTitle,
  DivChannels,
};
