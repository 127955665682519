import styled, { css, keyframes } from 'styled-components';

const gradient = keyframes`
  0% {
    background-color: #EBE6FE;
  }
  70% {
    background-color: #EBE6FE;
  }
  100% {
    background-color: initial;
  }
`;

const Message = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
  position: relative;
  background-color: ${({ hovered }) => (hovered ? '#efefef' : 'initial')};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  ${({ anchor }) => anchor && css`
    animation: ${gradient} 5s ease-in-out;
  `}
`;

const contentMessage = css`
  width: calc(100% - 4.9vh - 1vw);
`;

const nameDate = css`
  display: flex;
  align-items: baseline;
  margin: 0.4vh 0;
`;

const DivPicture = styled.div<any>`
  display: flex;
  visibility: ${({ mergeMessage }) => (mergeMessage ? 'hidden' : 'visible')};
  justify-content: center;
  align-items: center;
  max-width: 4.9vh;
  max-height: 4.9vh;
  min-width: 4.9vh;
  min-height: 4.9vh;
  min-height: ${({ mergeMessage }) => (mergeMessage ? '0' : '4.9vh')};
  max-height: ${({ mergeMessage }) => (mergeMessage ? '0' : '4.9vh')};
  border-radius: 50%;
  margin: 0 1vw 0 0;
  cursor: pointer;
  background-color: var(--color-light-royal-blue);
  border: 0.15625vw solid var(--color-light-royal-blue);
`;

const profileImage = css`
  max-width: 4.9vh;
  max-height: 4.9vh;
  min-width: 4.9vh;
  min-height: 4.9vh;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
`;

const acrony = css`
  color: var(--color-black);
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-18);
`;

const name = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  font-weight: 600;
  line-height: 0.86;
  text-align: left;
  color: #575757;
  text-transform: capitalize;
`;

const date = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-14);
  text-align: left;
  line-height: 0.86;
  color: #575757;
  margin-left: 0.5vw;
`;

const roles = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-14);
  font-style: italic;
  line-height: 1;
  text-align: left;
  color: var(--color-deep-sky-blue);
  margin-bottom: 0.5vh;


  span {
    color: var(--color-light-royal-blue);
  }
`;

const delimitation = css`
  display: flex;
  margin-bottom: 2vh;
  margin-top: 1vh;
  position: relative;
`;

const PDay = styled.p<any>`
  display: flex;
  justify-content: center;
  font-family: var(--font-family-sans);
  font-size: var(--size-16);
  font-weight: 600;
  text-align: left;
  color: ${({ hasNewMessagesBar }) => (hasNewMessagesBar ? '#d02222' : '#575757')};;
  position: absolute;
  line-height: 2vh;
  background: ${({ mode }) => (mode === 'reply' ? 'white' : '#f5f5f5')};
  padding: 0 1vw;
  top: -0.1vh;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
  white-space: nowrap;
`;

const PDayNewMess = styled.p<any>`
  display: flex;
  justify-content: center;
  font-family: var(--font-family-sans);
  font-size: var(--size-16);
  font-weight: 600;
  text-align: left;
  color: #575757;
  /* position: absolute; */
  line-height: 2vh;
  background: ${({ mode }) => (mode === 'reply' ? 'white' : '#f5f5f5')};
  padding: 0 1vw;
  top: -0.5vh;
  /* left: 0; */
  right: 0;
  margin: auto;
  width: min-content;
  white-space: nowrap;
  color: #d02222;
  
  /* animation: ${gradient} 5s ease-in-out; */
`;

const delim1 = css`
  height: 1px;
  width: 100%;
  background-color: black;
  margin: auto 0;
`;

const delimNew = css`
  height: 1px;
  width: 100%;
  background-color: #d02222;
  margin: auto 0;
`;

const delim2 = css`
  height: 1px;
  width: 55%;
  background-color: black;
  margin: auto 0;
`;

const content = css`
  width: 41.667vw;
`;

const DivLiked = styled.button<any>`
  display: flex;
  align-items: center;
  background-color: #ecebeb;
  padding: 0.1vh 1.5vw;
  width: fit-content;
  border-radius: 15px;
  cursor: pointer;
  margin-left: calc(4.9vh + 1vw);
  margin-top: 0.5vh;


  border: ${({ liked }) => (liked ? 'solid 1px var(--color-light-royal-blue)' : 'initial')};
  background-color: ${({ liked }) => (liked ? '#ebe6ff' : '#ecebeb')};

  span {
    color: #575757;
    font-family: var(--font-family-sans);
    font-size: var(--size-16);
    margin-left: 0.75vw;
  }
`;

const DivReplies = styled.button<any>`
  width: fit-content;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin-left: calc(4.9vh + 1vw);
  margin-top: 0.5vh;
  background-color: initial;
  padding: 0;

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }

  span {
    color: #575757;
    font-family: var(--font-family-sans);
    font-size: var(--size-16);
    font-weight: bold;
    text-align: left;
  }
`;

const dateHover = css`
  position: absolute;
  font-size: var(--size-14);
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
`;

const like = css`
    width: 1.6vw;
    height: 1.6vw;
`;

export default {
  delimitation,
  PDay,
  delim1,
  delimNew,
  dateHover,
  DivReplies,
  like,
  delim2,
  content,
  date,
  DivLiked,
  name,
  roles,
  DivPicture,
  acrony,
  profileImage,
  contentMessage,
  nameDate,
  Message,
  PDayNewMess,
};
