import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { useRouter } from 'next/router';
import useAuth from 'hooks/useAuth';

interface LaunchOfferProps {
  children: ReactNode
}

export type LaunchOfferContextType = {
  displayOffer: boolean
  displayHeadband: boolean
  dateLimitOffer: Date
  endOffer: Date
  startOffer: Date
  isOfferAvailable: boolean
  setDisplayOffer: (bool: boolean) => void
}

const initialContext = {
  displayOffer: false,
  displayHeadband: false,
  dateLimitOffer: new Date(2023, 1, 1),
  startOffer: new Date('2023-01-11T00:01:00'),
  endOffer: new Date('2023-01-17T23:59:00'),
  isOfferAvailable: false,
  setDisplayOffer: () => {},
};

export const LaunchOfferContext = createContext<LaunchOfferContextType>(initialContext);

const LaunchOfferProvider = ({ children }: LaunchOfferProps) => {
  const [displayOffer, setDisplayOffer] = useState(initialContext.displayOffer);
  const [displayHeadband, setDisplayHeadband] = useState(initialContext.displayOffer);
  const { query } = useRouter();

  const { dateLimitOffer, startOffer, endOffer } = initialContext;

  const { user, isSubscribed } = useAuth();

  // const today = new Date();
  const isOfferAvailable = false;
  useEffect(() => {
    setDisplayHeadband(false);
  }, [user, isSubscribed, query]);
  return (
    <LaunchOfferContext.Provider value={{
      displayOffer, displayHeadband, setDisplayOffer, dateLimitOffer, startOffer, endOffer, isOfferAvailable,
    }}
    >
      {children}
    </LaunchOfferContext.Provider>
  );
};

export default LaunchOfferProvider;
