import styled from 'styled-components';

const HoverTools = styled.div`
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute;
  right: 5vw;
  /* top: -1vh; */
  display: flex;
  align-items: center;
  z-index: 50;
`;

const ButtonTool = styled.div<any>`
  position: relative;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  margin: 0 0.3vw;
  cursor: pointer;
  background: none;
  padding: unset;
  font-size: var(--size-27);
  border-radius: 4px;
  padding: ${({ padding }) => padding || '0.7vh'};

  :hover {
    background-color: #c7c7c747;
  }
`;

export default {
  HoverTools,
  ButtonTool,
};
