import { css } from 'styled-components';

const urlInputContainer = css`
  position: fixed;
  background: white;
  width: 30vw;
  height: min-content;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 2px 15px 0px #00000070;
  padding: 2vh 2vw;
  z-index: 100;
`;

const title = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-20);
  font-weight: bold;
  margin: 1vh 0;
`;

const label = css`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-sans);
  font-size: var(--size-17);
`;

const input = css`
  height: 4vh;
  border: 1px solid black;

`;

const buttonBack = css`
  background: white;
  margin: auto;
  height: 1.5vh;
  width: 1.5vh;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const buttonSave = css`
/* border: 1px solid white; */
  margin: 1vh 1vw 0 0;
  float: right;
  border: 1px solid;
  background: none;
  color: var(--color-black);
  border-radius: 4px;
  min-width: 7vw;
`;

const buttonDel = css`
/* border: 1px solid white; */
  margin: 1vh 1vw 0 0;
  float: right;
  border: 1px solid #db2727;
  background: #db2727;
  color: white;
  border-radius: 4px;
  min-width: 7vw;
`;

const textToEdit = css`
  position :relative;
`;

export default {
  urlInputContainer,
  label,
  input,
  textToEdit,
  title,
  buttonSave,
  buttonDel,
  buttonBack,
};
