import { css } from 'styled-components';

const echangerWrap = css`
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(to bottom, var(--color-light-royal-blue), #fff 38%, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const title = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-36);
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  margin-top: 5.589vh;
  margin-bottom: 4.629vh;
`;

const charte = css`
  width: 45.73vw;
  padding: 5.37vh 3.698vw 0;
  border-radius: 21px;
  background-color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 6vh;

  p {
  }
`;

const leftSide = css`
  position: absolute;
  bottom: 0;
  left: 3.195vw;
`;

const women = css`
  width: 13vw;
  height: 44.44vh;
`;

const bulle = css`
  position: relative;
  width: 21.52vw;

  p {
    position: absolute;
    top: 3.5vh;
    right: 1.3vw;
    font-family: var(--font-family-sans);
    font-size: var(--size-27);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    width: 19.5vw;
  }
`;
const scroll = css`
  overflow: auto;
  width: 100%;
  padding-left: 13px;
`;

const button = css`
  margin: 4vh auto;
`;

export default {
  echangerWrap,
  title,
  charte,
  leftSide,
  scroll,
  women,
  button,
  bulle,
};
