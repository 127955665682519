import styled, { css } from 'styled-components';

interface ButtonProps {
  loading: boolean
}

const Button = styled.button<ButtonProps>`
  display: flex;
  padding: 1vh 2vh;
  background: var(--color-black);
  color: white;
  position: relative;
  margin: auto;

  span {
    visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')}
  }
`;

const loader = css`
  position: absolute;
  height: 2vh;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const asArrow = css`
    width: 16px;
    height: 24px;
    margin-right: 0.5vw;
`;

const reverseArrow = css`
  transform: rotate(180deg);
`;

export default {
  Button,
  asArrow,
  reverseArrow,
  loader,
};
