import {
  SvgBold, SvgEmoji, SvgItalic, SvgLink, SvgOrdered, SvgUnderline, SvgUnordered,
} from 'components/Svgs/exchangesSvgs';
import SC from './style';

export const Util = ({ type }) => (
  <div css={SC.utilSvg}>
    {type === 'bold' && <SvgBold />}
    {type === 'italic' && <SvgItalic />}
    {type === 'underline' && <SvgUnderline />}
    {type === 'ordered' && <SvgOrdered />}
    {type === 'unordered' && <SvgUnordered />}
    {type === 'emoji' && <SvgEmoji />}
    {type === 'link' && <SvgLink />}
  </div>
);

const BLOCK_TYPES = [
  { label: <Util type="ordered" />, style: 'ordered-list-item' },
  { label: <Util type="unordered" />, style: 'unordered-list-item' },
  { label: <Util type="link" />, style: 'LINK' },
];

const INLINE_STYLES = [
  { label: <Util type="bold" />, style: 'BOLD' },
  { label: <Util type="italic" />, style: 'ITALIC' },
  { label: <Util type="underline" />, style: 'UNDERLINE' },
];

const StyleButton = ({
  style, onToggle, active, label,
}:any) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  const className = !active ? 'RichEditor-styleButton' : 'RichEditor-styleButton RichEditor-activeButton';

  return (
    <span role="presentation" className={className} onMouseDown={onMouseDown}>
      {label}
    </span>
  );
};

export const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState?.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
