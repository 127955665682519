import { css } from 'styled-components';

const urlInputContainer = css`
  position: fixed;
  background: white;
  width: 30vw;
  max-height: 30vh;
  height: min-content;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 2px 15px 0px #00000070;
  padding: 0 2vw;
  z-index: 100;
`;

const title = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-25);
  font-weight: bold;
  margin: 1vh 0;
  position: relative;
`;

const label = css`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-sans);
  font-size: var(--size-17);
  margin-top: 1vh;

  span {
    font-weight: 600;
  }

`;

const input = css`
  height: 4vh;
  border: 1px solid #00000066;
  border-radius: 4px;

  &:focus-visible {
    border: 1px solid var(--color-deep-sky-blue);
    box-shadow: 0px 0 4px var(--color-deep-sky-blue);
    outline: none;
    border-radius: 4px;
  }
`;

const buttonBack = css`
  margin: 2vh 0 2vh 1vw;
  float: right;
  border: 1px solid;
  background: none;
  color: var(--color-black);
  border-radius: 4px;
  min-width: 7vw;
`;

const buttonClose = css`
  background: white;
  margin: auto;
  height: 1.5vh;
  width: 1.5vh;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const buttonSave = css`
  margin: 2vh 0 2vh 1vw;
  float: right;
  border: 1px solid var(--color-deep-sky-blue);
  background: var(--color-deep-sky-blue);
  color: white;
  border-radius: 4px;
  min-width: 7vw;
`;

export default {
  urlInputContainer,
  label,
  input,
  title,
  buttonClose,
  buttonSave,
  buttonBack,
};
