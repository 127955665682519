import { css } from 'styled-components';

const advBlock = css`
  width: 16.71875vw;
  margin-right: 1.386vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 1vh;
`;

const advImage = css`
  position: relative;
  width: 16.71875vw;
  height: 14.16vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const hnpInfo = css`
  font-family: var(--font-family-serif);
  font-size: var(--size-14);
  font-style: italic;
  line-height: 1.43;
  text-align: center;
  color: #000;
  margin-top: 0.5vh;
`;

const lastelem = css`
  margin-right: 0;
`;

const imgTitle = css`
  z-index: 10;
  color: white;
  text-align: center;
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-23);
  text-transform: uppercase;
  line-height: 1.13;
  margin-bottom: 0.5vh;


  b {
    font-size: var(--size-30);
  }

  span {
    font-size: var(--size-14);
  }
`;

const imgSubTitle = css`
  z-index: 10;
  color: white;
  text-align: center;
  line-height: 1.1;
  /* margin-bottom: -2vh; */
  font-family: var(--font-family-serif);
  font-size: var(--size-14);
  font-style: italic;
`;

const premiumImg = css`
  width: calc(var(--card-img-price-w) * 1.2);
  height: calc(var(--card-img-price-h) * 1.2);
  position: absolute;
  margin-bottom: calc(-1.2 * var(--card-img-price-h));
  top: var(--card-img-price-top);
  margin-left: var(--card-img-price-m);
  left: 0.5vw;
  z-index: 40;
`;

export default {
  advImage,
  premiumImg,
  lastelem,
  imgTitle,
  imgSubTitle,
  advBlock,
  hnpInfo,
};
