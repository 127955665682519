import Image from 'next/image';
import { DivFlex } from 'styles/common';
import SC from './style';
import ImgPrmm from '../../../public/prmm.svg';
import useLaunchOffer from 'hooks/useLaunchOffer';

interface AdvantagesProps {
  isHNPROMember: any
}

const Advantages: React.FC<AdvantagesProps> = ({ isHNPROMember }) => {
  const { dateLimitOffer } = useLaunchOffer();
  const today = new Date();
  return (
    <DivFlex wrap="wrap" justify="center">
      <div css={SC.advBlock}>
        <div css={SC.advImage}>
          {!isHNPROMember && (
          <div css={SC.premiumImg}>
            <Image src={ImgPrmm} alt="premium content" fill sizes="100vw" />
          </div>
          )}
          <Image src="/Avantage1.png" alt="connect-logo" fill sizes="100vw" />
          {!isHNPROMember ? (
            <>
              <p css={SC.imgTitle}>
                Accès aux
                <br />
                {' '}
                contenus premium
              </p>
              <p css={SC.imgSubTitle}>Nouveautés, articles scientifiques, podcasts, interviews, etc.</p>
            </>
          ) : (
            <>
              <p css={SC.imgTitle}>
                TARIF PRÉFÉRENTIEL DE 20€/mois*
              </p>
              <p css={SC.imgSubTitle}>
                Au lieu de 29€/mois
                <br />
                soit une économie de 108€ chaque année

              </p>
            </>
          )}
        </div>
        {isHNPROMember && (
        <p css={SC.hnpInfo}>
          *Offre réservée aux abonnés à la plateforme orthophonie
        </p>
        )}
      </div>
    </DivFlex>
  );
};

export default Advantages;
