import styled from 'styled-components';

export const Dot = styled.div<any>`
    width: 1.719vw;
    height: 1.719vw;
    min-width: 1.719vw;
    min-height: 1.719vw;
    margin: auto 2px auto auto;
    background-color: var(--color-light-royal-blue);
    border-radius: 50%;
    /* top: -0.7vh; */
    right: 0.1vw;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const Nbr = styled.div<any>`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-16);
  color: white;
`;
