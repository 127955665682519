import { css } from 'styled-components';

const echangerWrap = css`
  width: 79%;
  height: 100%;
  display: flex;
  background: linear-gradient(to bottom, var(--color-light-royal-blue), #17007d 38%, #17007d);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const SvgPlaceholder = css`
  position: relative;
  width: 21.52vw;
  margin: 2vh 0;
`;

const text1 = css`
  font-family: SourceSansPro;
  font-size: var(--size-34);
  font-weight: bold;
  line-height: 1.24;
  text-align: center;
  color: #fff;
  width: 37vw;
  margin-top: 4vh;
  z-index: 50;
`;

const text2 = css`
  font-family: SourceSansPro;
  font-size: var(--size-27);
  line-height: 1.22;
  text-align: center;
  color: #fff;
  width: 37vw;
  margin-bottom: 2vh;
  margin-top: 2vh;
  z-index: 50;
`;

const accessButton = css`
  min-height: 7vh;
  padding: 0 1.5vw;
  background: var(--color-black);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: var(--size-13);
    color: white;
    line-height: 1.2;
  }

  span {
    /* margin: 2vh 2vw auto 2vw; */
    color: white;
    font-family: var(--font-family-sans);
    font-weight: 600;
    font-size: var(--size-26);
    text-transform: uppercase;
    line-height: 1.2;
  }
`;

export default {
  echangerWrap,
  SvgPlaceholder,
  accessButton,
  text1,
  text2,
};
