import { css } from 'styled-components';

const echangerWrap = css`
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(to bottom, var(--color-light-royal-blue), #fff 38%, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const loadingPage = css`
  margin: auto;
  height: 6vh;
`;

export default {
  echangerWrap,
  loadingPage,
};
