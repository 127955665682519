import useExchanges from 'hooks/useExchanges';
import NotAuthorized from 'components/Exchange/NotAuthorized';
import Placeholder from 'components/Exchange/Placeholder';
import ExchangeHome from 'components/Exchange/Home';
import Charter from 'components/Exchange/Charter';
import useAuth from 'hooks/useAuth';

const Exchanges: React.FC = () => {
  const { user } = useAuth();
  const { state } = useExchanges();

  if (!user) return <NotAuthorized />;

  if (state.loading) return <Placeholder />;

  if (!state.acceptedPolicy) return <Charter user={user} />;

  return <ExchangeHome />;
  // return null;
};

export default Exchanges;
