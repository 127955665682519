import styled, { css } from 'styled-components';

const customButton = css`
  border-radius: 28px;
  background: rgb(46, 46, 46);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  position: absolute;
  right: 0;
  left: 0;
  top: -6vh;
  width: fit-content;
`;

const customButtonChannel = css`
  border-radius: 28px;
  background: rgb(46, 46, 46);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  position: absolute;
  right: 0;
  left: 0;
  top: 1vh;
  width: fit-content;
  z-index: 50;
`;

const customButtonChannelBottom = css`
  border-radius: 28px;
  background: rgb(46, 46, 46);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 1vh;
  width: fit-content;
  z-index: 50;
`;

const echangerHome = css`
  width: 100%;
  height: 100%;
  display: flex;
`;

const listChannels = css`
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #fff, #f9f7ff 51%, rgba(88, 51, 255, 0.6));
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const listChannelsFixed = css`
  position: relative;
  width: 21%;
  min-width: 21%;
  background: linear-gradient(to bottom, #fff, #f9f7ff 51%, rgba(88, 51, 255, 0.6));
  display: flex;
  flex-direction: column;
`;

const home = css`
  width: 79%;
  position: relative;
  background-color: #f5f5f5;
  padding: 0 1vw;
`;

const titleChannels = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-25);
  font-weight: 600;
  line-height: 0.72;
  text-align: left;
  color: #17007d;
  height: 5.37vh;
  display: inline-flex;
  align-items: center;
  padding-left: 1.771vw;
`;

const titleDelimiter = css`
  border: solid 0.09vh #4b5297;
`;

const PChannel = styled.p<any>`
  font-family: var(--font-family-sans);
  font-size: var(--size-21);
  font-weight:  ${({ isCategory }) => (isCategory ? '600' : '300')};
  line-height: 0.86;
  text-align: left;
  color: #fff;
  height: 3.15vh;
  display: inline-flex;
  align-items: center;
  margin-left: ${({ isCategory }) => (isCategory ? 'initial' : '1.073vw')};
  cursor: pointer;
`;

const blockChannels = css`
  padding-left: 1.771vw;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
`;

const polygone = css`
  width: 0.573vw;
  height: 0.9259vh;
  position: relative;
  margin-right: 0.5vw;
`;

const blockTitle = css`
  display: flex;
  align-items: baseline;
`;

const Channels = styled.div<any>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

const channelName = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-25);
  font-weight: 600;
  line-height: 0.72;
  text-align: left;
  color: #575757;
  margin-left: 3.802vw;
  height: 7%;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
`;

const editorHead = css`
  width: 100%;
  height: 4vh;
  background-color: #eee;
`;

const textarea = css`
  width: 100%;
  height: calc(21.85vh - 4vh);
  padding: 1vw;
  border-radius: 16px;
`;
const form = css`
  display: flex;
  flex-direction: column;
`;

const sendButton = css`
  position: absolute;
  height: 2.96vh;
  width: 2.656vw;
  min-width: 37px;
  min-height: 23px;
  bottom: 0.6vh;
  right: 1.3vw;
  padding: 0;
`;

const messages = css`
  overflow-y: auto;
  height: 93%;
  padding: 0 1vw 20vh 1vw;
  display: flex;
  flex-direction: column-reverse;
`;

const miniLoader = css`
  width: 3vw;
  margin: 1vh auto;
  display: inline-flex;
`;

const endMessages = css`
  margin: auto;
  background: #dfdfdf;
  border-radius: 100px;
  margin: 1vh auto;
  padding: 0.3vh 1vw;
`;

const editorMain = css`
    bottom: 2vh;
    left: 0;
    right: 0;
    margin-left: 1vw;
    width: 85%;
    max-height: 50vh;
    z-index: 50;
    position: absolute;
    box-shadow: 0px 20px 20px 0px white;
`;

const editorReply = css`
    bottom: 2vh;
    left: 0;
    right: 0;
    margin-left: 1vw;
    width: calc(100% - 2vw);
    max-height: 50vh;
    position: absolute;
    z-index: 50;
    box-shadow: 0px 20px 20px 0px white;
`;

const replyMessage = css`
  width: 50vw;
  min-width: 25vw;
  background: white;
  position: relative;
`;

const closeReply = css`
  width: 4vh;
  height: 4vh;
  cursor: pointer;
  position: absolute;
  right: 2vw;
`;

const replyTitle = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-25);
  font-weight: 600;
  text-align: left;
  color: #575757;
  line-height: 0;
`;

const replyTitleName = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-12);
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  color: #575757;
  margin-left: 0.5vw;
`;

const replyHead = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  background: initial;
  justify-content: space-between;
  margin-left: 3.802vw;
  height: 7%;
  line-height: 0.72;
`;

const fadeBackground = css`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #0000003d;
  z-index: 50;
`;

const placeholder = css`
  margin: auto;
  font-size: var(--size-17);
  text-align: center;
  color: var(--color-black);
  padding: 0 3vh;
`;

const newMessages = css`
  display: flex;
  justify-content: center;
  cursor: pointer;

  p {
    background-color: #a1a1a18f;
    color: black;
    padding: 0.5vh 1vw;
    border-radius: 25px;
    margin-bottom: 1vh;
  }
`;

export default {
  form,
  closeReply,
  placeholder,
  replyTitle,
  replyHead,
  customButtonChannelBottom,
  echangerHome,
  fadeBackground,
  customButtonChannel,
  replyTitleName,
  newMessages,
  listChannels,
  listChannelsFixed,
  editorMain,
  replyMessage,
  editorReply,
  endMessages,
  messages,
  miniLoader,
  sendButton,
  textarea,
  home,
  channelName,
  titleChannels,
  titleDelimiter,
  PChannel,
  editorHead,
  blockChannels,
  polygone,
  blockTitle,
  Channels,
  customButton,
};
