import {
  RichUtils, getDefaultKeyBinding,
} from 'draft-js';

const keyBindingFn = (e, editorState, setEditorState) => {
  const controlKey = e.nativeEvent.ctrlKey || e.nativeEvent.altKey || e.nativeEvent.metaKey;
  if (e.keyCode === 13) { // Enter key
    if (controlKey || e.nativeEvent.shiftKey) {
      return 'handled-split-block';
    }
  }
  if (e.keyCode === 66) { // B key
    if (controlKey) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
      return 'not-handled';
    }
  }
  if (e.keyCode === 73) { // I key
    if (controlKey) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
      return 'not-handled';
    }
  }
  if (e.keyCode === 83) { // S key
    if (controlKey) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
      return 'not-handled';
    }
  }
  return getDefaultKeyBinding(e);
};

export default keyBindingFn;
