import { SvgClose } from 'components/Svgs';
import SC from './style';
import ReadOnlyLink from './ReadOnlyLink';

interface ManageUrlProps {
    confirmLink: any
    data: any
}

// eslint-disable-next-line react/display-name
const ManageUrl: React.FC<ManageUrlProps> = ({ confirmLink, data }) => {
  const handleSubmit = (e, choice) => {
    e.preventDefault();
    confirmLink(e, choice);
  };
  return (
    <div css={SC.urlInputContainer}>

      <div>
        <p css={SC.textToEdit}>
          {data.decoratedText}
          <button
            css={SC.buttonBack}
            type="button"
            onClick={(e) => handleSubmit(e, 'back')}
          >
            <SvgClose />
          </button>
        </p>
        <p>
          <ReadOnlyLink {...data} />
        </p>
      </div>
      <div>
        <button css={SC.buttonDel} type="button" onClick={(e) => handleSubmit(e, 'delete')}>Supprimer</button>
        <button css={SC.buttonSave} type="button" onClick={(e) => handleSubmit(e, 'edit')}>Edit</button>
      </div>
    </div>
  );
};

export default ManageUrl;
