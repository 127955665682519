import { SvgSendMessage } from 'components/Svgs';
import SC from './style';

interface FooterProps {
    messageLen: number
    maxLen: number
    handleSubmit: (e: any, saved?: any) => Promise<void>
    disabledForm: boolean
    readOnly: boolean
}

const Footer: React.FC<FooterProps> = ({
  disabledForm, handleSubmit, maxLen, messageLen, readOnly,
}) => (

  <div css={SC.divButton}>
    {messageLen > maxLen && (
    <p css={SC.maxLen}>
      -
      {messageLen - maxLen}
    </p>
    )}
    <p css={SC.helpSend}>Utilisez ⇧ + Entrée pour aller à la ligne</p>
    {readOnly ? (
      <div css={SC.editionSubmit}>
        <button
          type="button"
          onClick={(e) => handleSubmit(e, false)}
          css={SC.buttonBack}
        >
          Retour
        </button>
        <button type="button" onClick={handleSubmit} css={SC.buttonSubmit}>Sauvegarder</button>
      </div>
    ) : (
      <button
        aria-label="Envoyer un message"
        css={SC.sendButton}
        type="submit"
      >
        <SvgSendMessage disabled={disabledForm} />
      </button>
    )}
  </div>
);

export default Footer;
