import Link from 'next/link';
import Image from 'next/image';
import Advantages from 'components/Advantages';
import { SvgEchangeDisabled } from 'components/Svgs';
import SC from './style';
import { getSubscriptionLink, openSubscribtion } from '../../../utils/link';

interface ReservedProps {
 user: any
}

const Reserved: React.FC<ReservedProps> = ({ user }) => (
  <div css={SC.echangerWrap}>
    <Image src="/vitre-fake-flou1.png" alt="reserved" fill sizes="100vw" />
    <p css={SC.text1}>Ce canal est réservé aux abonnés Happyneuron</p>
    <div css={SC.SvgPlaceholder}>
      <SvgEchangeDisabled />
    </div>

    <Link href={getSubscriptionLink()} target="_blank" css={SC.accessButton}>
      <span>Je m'abonne à Happyneuron</span>
    </Link>

    <Link href={getSubscriptionLink()} target="_blank" css={SC.text2}>
      <p>
        En vous abonnant, profitez de ce canal en illimité, d'accès privilégiés aux événements de l'Academy, mais aussi des avantages suivants :
      </p>
    </Link>

    <Advantages isHNPROMember={user?.profile?.isHNPROMember} />
  </div>
);

export default Reserved;
