import { SvgLogoSmallLoader } from 'components/Svgs';
import { SvgArrowUp } from 'components/Svgs/exchangesSvgs';
import SC from './style';

interface ButtonProps {
  onClick: any
  loading?: boolean
  value: string
  arrow?: string
}

const Button: React.FC<ButtonProps> = ({
  onClick, loading, value, arrow, ...props
}) => (
  <SC.Button loading={loading} type="button" role="link" onClick={onClick} {...props}>
    {arrow && (
      <div css={[SC.asArrow, arrow === 'up' && SC.reverseArrow]}>
        <SvgArrowUp />
      </div>
    )}
    <span>{value}</span>
    {loading && <div css={SC.loader}><SvgLogoSmallLoader /></div>}
  </SC.Button>
);

export default Button;
