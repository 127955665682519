import { useEffect, useState } from 'react';

export default function useOnScreen(ref, index, id, channels) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    if (ref?.current[index]) {
      observer?.observe(ref.current[index]);
    }
    return () => { observer?.disconnect(); };
  }, [index, id, channels]);

  return isIntersecting;
}
