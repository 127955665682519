import { css } from 'styled-components';

const anchorUrl = css`
  color: var(--color-light-royal-blue);

  &:hover {
    color: var(--color-light-royal-blue);
    text-decoration: underline;
  }
`;

const ViewedLink = (props) => {
  const {
    contentState, entityKey, children, onClick,
  } = props;
  const { url } = contentState?.getEntity(entityKey).getData();
  return (
    <a
      css={anchorUrl}
      href={url}
      onClick={async (e) => {
        e.preventDefault();
        onClick({ ...props, url });
      }}
    >
      {children}
    </a>
  );
};

export default ViewedLink;
