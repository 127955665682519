/* eslint-disable max-len */
import {
  Dot, Nbr,
} from './style';

interface IBulle {
  count: number
}

const BulleExchanges: React.FC<IBulle> = ({
  count,
}) => (
  <Dot>
    <Nbr>{count}</Nbr>
  </Dot>
);

export default BulleExchanges;
