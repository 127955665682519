/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ProfileInput = {
  name: string,
  role: Array< string | null >,
  picture?: string | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  name: string,
  email: string,
  picture?: string | null,
  role: Array< string | null >,
  privacy: boolean,
  createdAt: string,
  updatedAt?: string | null,
  likesCount: number,
};

export type Channel = {
  __typename: "Channel",
  id: string,
  categoryName: string,
  name: string,
  type: string,
  category: string,
  premium: boolean,
  createdAt?: string | null,
  messageCount: number,
  userRead?: LastUserRead | null,
  trainingHnaId?: number | null,
};

export type LastUserRead = {
  __typename: "LastUserRead",
  lastUserRead?: string | null,
  unreadUserMessage?: number | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  channelId: string,
  content: string,
  createdAt: string,
  updatedAt: string,
  profile: Profile,
  replies: number,
  type: string,
  likesCount: number,
  liked: boolean,
};

export type IMessage = {
  __typename: "IMessage",
  id: string,
  channelId: string,
  createdAt: string,
};

export type Reply = {
  __typename: "Reply",
  id: string,
  channelId: string,
  content: string,
  createdAt: string,
  updatedAt?: string | null,
  profile: Profile,
  inReplyToMessage: string,
  type: string,
  likesCount: number,
  liked: boolean,
};

export type EditMessage = {
  __typename: "EditMessage",
  id: string,
  channelId: string,
  content: string,
  createdAt: string,
  updatedAt: string,
  replies: number,
  type: string,
  likesCount: number,
};

export type EditReply = {
  __typename: "EditReply",
  id: string,
  channelId: string,
  content: string,
  createdAt: string,
  updatedAt?: string | null,
  inReplyToMessage: string,
  type: string,
  likesCount: number,
};

export type CreateMessage = {
  __typename: "CreateMessage",
  id: string,
  channelId: string,
  content: string,
  createdAt: string,
  replies: number,
  type: string,
  likesCount: number,
};

export type DeleteMessage = {
  __typename: "DeleteMessage",
  id: string,
  channelId: string,
  status: boolean,
};

export type DeleteReply = {
  __typename: "DeleteReply",
  id: string,
  channelId: string,
  inReplyToMessage: string,
  status: boolean,
};

export type LikeMessage = {
  __typename: "LikeMessage",
  id: string,
  channelId: string,
  inReplyToMessage?: string | null,
  likesCount?: number | null,
  status: boolean,
};

export type userMessageRead = {
  __typename: "userMessageRead",
  channelId: string,
  userId: string,
  messageId: string,
  createdAt: string,
  updatedAt: string,
};

export type Notification = Liked | Replied


export type Liked = {
  __typename: "Liked",
  id: string,
  type: NotificationType,
  userId: string,
  channelId: string,
  messageId: string,
  likedBy: string,
  createdAt: string,
};

export type INotification = {
  __typename: "INotification",
  id: string,
  type: NotificationType,
  userId: string,
  createdAt: string,
};

export type Replied = {
  __typename: "Replied",
  id: string,
  type: NotificationType,
  userId: string,
  channelId: string,
  messageId: string,
  replyId: string,
  repliedBy: string,
  createdAt: string,
};

export enum NotificationType {
  Liked = "Liked",
  Replied = "Replied",
}


export enum DirectionType {
  ASC = "ASC",
  DESC = "DESC",
}


export type MessagePage = {
  __typename: "MessagePage",
  messages?:  Array<Message > | null,
  nextToken?: string | null,
};

export type ReplyPage = {
  __typename: "ReplyPage",
  replies?:  Array<Reply > | null,
  nextToken?: string | null,
};

export type ProfileMutationVariables = {
  newProfile: ProfileInput,
};

export type ProfileMutation = {
  profile:  {
    __typename: "Profile",
    id: string,
    name: string,
    email: string,
    picture?: string | null,
    role: Array< string | null >,
    privacy: boolean,
    createdAt: string,
    updatedAt?: string | null,
    likesCount: number,
  },
};

export type EditProfileMutationVariables = {
  newProfile: ProfileInput,
};

export type EditProfileMutation = {
  editProfile:  {
    __typename: "Profile",
    id: string,
    name: string,
    email: string,
    picture?: string | null,
    role: Array< string | null >,
    privacy: boolean,
    createdAt: string,
    updatedAt?: string | null,
    likesCount: number,
  },
};

export type ChannelMutationVariables = {
  name: string,
  category: string,
  premium: boolean,
};

export type ChannelMutation = {
  channel:  {
    __typename: "Channel",
    id: string,
    categoryName: string,
    name: string,
    type: string,
    category: string,
    premium: boolean,
    createdAt?: string | null,
    messageCount: number,
    userRead?:  {
      __typename: "LastUserRead",
      lastUserRead?: string | null,
      unreadUserMessage?: number | null,
    } | null,
    trainingHnaId?: number | null,
  },
};

export type EditChannelMutationVariables = {
  id: string,
  name: string,
  category: string,
  premium: boolean,
};

export type EditChannelMutation = {
  editChannel:  {
    __typename: "Channel",
    id: string,
    categoryName: string,
    name: string,
    type: string,
    category: string,
    premium: boolean,
    createdAt?: string | null,
    messageCount: number,
    userRead?:  {
      __typename: "LastUserRead",
      lastUserRead?: string | null,
      unreadUserMessage?: number | null,
    } | null,
    trainingHnaId?: number | null,
  },
};

export type MessageMutationVariables = {
  channelId: string,
  content: string,
};

export type MessageMutation = {
  message:  {
    __typename: "Message",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    replies: number,
    type: string,
    likesCount: number,
    liked: boolean,
  },
};

export type EditMessageMutationVariables = {
  id: string,
  channelId: string,
  content: string,
};

export type EditMessageMutation = {
  editMessage:  {
    __typename: "EditMessage",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    replies: number,
    type: string,
    likesCount: number,
  },
};

export type DeleteMessageMutationVariables = {
  id: string,
  channelId: string,
};

export type DeleteMessageMutation = {
  deleteMessage:  {
    __typename: "DeleteMessage",
    id: string,
    channelId: string,
    status: boolean,
  },
};

export type ReplyMutationVariables = {
  messageId: string,
  channelId: string,
  content: string,
};

export type ReplyMutation = {
  reply:  {
    __typename: "Reply",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    inReplyToMessage: string,
    type: string,
    likesCount: number,
    liked: boolean,
  },
};

export type EditReplyMutationVariables = {
  id: string,
  channelId: string,
  content: string,
};

export type EditReplyMutation = {
  editReply:  {
    __typename: "EditReply",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt?: string | null,
    inReplyToMessage: string,
    type: string,
    likesCount: number,
  },
};

export type DeleteReplyMutationVariables = {
  id: string,
  messageId: string,
  channelId: string,
};

export type DeleteReplyMutation = {
  deleteReply:  {
    __typename: "DeleteReply",
    id: string,
    channelId: string,
    inReplyToMessage: string,
    status: boolean,
  },
};

export type LikeMessageMutationVariables = {
  messageId: string,
  channelId: string,
};

export type LikeMessageMutation = {
  likeMessage:  {
    __typename: "LikeMessage",
    id: string,
    channelId: string,
    inReplyToMessage?: string | null,
    likesCount?: number | null,
    status: boolean,
  },
};

export type UnlikeMessageMutationVariables = {
  messageId: string,
  channelId: string,
};

export type UnlikeMessageMutation = {
  unlikeMessage:  {
    __typename: "LikeMessage",
    id: string,
    channelId: string,
    inReplyToMessage?: string | null,
    likesCount?: number | null,
    status: boolean,
  },
};

export type LastUserReadMutationVariables = {
  messageId: string,
  channelId: string,
};

export type LastUserReadMutation = {
  lastUserRead:  {
    __typename: "userMessageRead",
    channelId: string,
    userId: string,
    messageId: string,
    createdAt: string,
    updatedAt: string,
  },
};

export type NotifyLikedMutationVariables = {
  id: string,
  userId: string,
  channelId: string,
  messageId: string,
  likedBy: string,
};

export type NotifyLikedMutation = {
  notifyLiked: ( {
      __typename: "Liked",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      likedBy: string,
      createdAt: string,
    } | {
      __typename: "Replied",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      replyId: string,
      repliedBy: string,
      createdAt: string,
    }
  ),
};

export type NotifyRepliedMutationVariables = {
  id: string,
  userId: string,
  channelId: string,
  messageId: string,
  replyId: string,
  repliedBy: string,
};

export type NotifyRepliedMutation = {
  notifyReplied: ( {
      __typename: "Liked",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      likedBy: string,
      createdAt: string,
    } | {
      __typename: "Replied",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      replyId: string,
      repliedBy: string,
      createdAt: string,
    }
  ),
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    name: string,
    email: string,
    picture?: string | null,
    role: Array< string | null >,
    privacy: boolean,
    createdAt: string,
    updatedAt?: string | null,
    likesCount: number,
  } | null,
};

export type ListChannelsQuery = {
  listChannels?:  Array< {
    __typename: "Channel",
    id: string,
    categoryName: string,
    name: string,
    type: string,
    category: string,
    premium: boolean,
    createdAt?: string | null,
    messageCount: number,
    userRead?:  {
      __typename: "LastUserRead",
      lastUserRead?: string | null,
      unreadUserMessage?: number | null,
    } | null,
    trainingHnaId?: number | null,
  } | null > | null,
};

export type ListMessageByChannelQueryVariables = {
  channelId: string,
  limit: number,
  sortDirection: DirectionType,
  nextToken?: string | null,
};

export type ListMessageByChannelQuery = {
  listMessageByChannel:  {
    __typename: "MessagePage",
    messages?:  Array< {
      __typename: "Message",
      id: string,
      channelId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        name: string,
        email: string,
        picture?: string | null,
        role: Array< string | null >,
        privacy: boolean,
        createdAt: string,
        updatedAt?: string | null,
        likesCount: number,
      },
      replies: number,
      type: string,
      likesCount: number,
      liked: boolean,
    } > | null,
    nextToken?: string | null,
  },
};

export type ListReplyByMessageQueryVariables = {
  messageId: string,
  limit: number,
  sortDirection: DirectionType,
  nextToken?: string | null,
};

export type ListReplyByMessageQuery = {
  listReplyByMessage:  {
    __typename: "ReplyPage",
    replies?:  Array< {
      __typename: "Reply",
      id: string,
      channelId: string,
      content: string,
      createdAt: string,
      updatedAt?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        name: string,
        email: string,
        picture?: string | null,
        role: Array< string | null >,
        privacy: boolean,
        createdAt: string,
        updatedAt?: string | null,
        likesCount: number,
      },
      inReplyToMessage: string,
      type: string,
      likesCount: number,
      liked: boolean,
    } > | null,
    nextToken?: string | null,
  },
};

export type OnNotifiedSubscriptionVariables = {
  userId: string,
  type?: NotificationType | null,
};

export type OnNotifiedSubscription = {
  onNotified: ( {
      __typename: "Liked",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      likedBy: string,
      createdAt: string,
    } | {
      __typename: "Replied",
      id: string,
      type: NotificationType,
      userId: string,
      channelId: string,
      messageId: string,
      replyId: string,
      repliedBy: string,
      createdAt: string,
    }
  ) | null,
};

export type OnMessageByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnMessageByChannelSubscription = {
  onMessageByChannel?:  {
    __typename: "Message",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    replies: number,
    type: string,
    likesCount: number,
    liked: boolean,
  } | null,
};

export type OnMessageSubscription = {
  onMessage?:  {
    __typename: "Message",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    replies: number,
    type: string,
    likesCount: number,
    liked: boolean,
  } | null,
};

export type OnEditMessageByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnEditMessageByChannelSubscription = {
  onEditMessageByChannel?:  {
    __typename: "EditMessage",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    replies: number,
    type: string,
    likesCount: number,
  } | null,
};

export type OnDeleteMessageByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnDeleteMessageByChannelSubscription = {
  onDeleteMessageByChannel?:  {
    __typename: "DeleteMessage",
    id: string,
    channelId: string,
    status: boolean,
  } | null,
};

export type OnLikeMessageByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnLikeMessageByChannelSubscription = {
  onLikeMessageByChannel?:  {
    __typename: "LikeMessage",
    id: string,
    channelId: string,
    inReplyToMessage?: string | null,
    likesCount?: number | null,
    status: boolean,
  } | null,
};

export type OnUnLikeMessageByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnUnLikeMessageByChannelSubscription = {
  onUnLikeMessageByChannel?:  {
    __typename: "LikeMessage",
    id: string,
    channelId: string,
    inReplyToMessage?: string | null,
    likesCount?: number | null,
    status: boolean,
  } | null,
};

export type OnReplyByMessageSubscriptionVariables = {
  inReplyToMessage: string,
};

export type OnReplyByMessageSubscription = {
  onReplyByMessage?:  {
    __typename: "Reply",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    inReplyToMessage: string,
    type: string,
    likesCount: number,
    liked: boolean,
  } | null,
};

export type OnReplyByChannelSubscriptionVariables = {
  channelId: string,
};

export type OnReplyByChannelSubscription = {
  onReplyByChannel?:  {
    __typename: "Reply",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      name: string,
      email: string,
      picture?: string | null,
      role: Array< string | null >,
      privacy: boolean,
      createdAt: string,
      updatedAt?: string | null,
      likesCount: number,
    },
    inReplyToMessage: string,
    type: string,
    likesCount: number,
    liked: boolean,
  } | null,
};

export type OnEditReplyByMessageSubscriptionVariables = {
  inReplyToMessage: string,
};

export type OnEditReplyByMessageSubscription = {
  onEditReplyByMessage?:  {
    __typename: "EditReply",
    id: string,
    channelId: string,
    content: string,
    createdAt: string,
    updatedAt?: string | null,
    inReplyToMessage: string,
    type: string,
    likesCount: number,
  } | null,
};

export type OnDeleteReplyByMessageSubscriptionVariables = {
  inReplyToMessage: string,
};

export type OnDeleteReplyByMessageSubscription = {
  onDeleteReplyByMessage?:  {
    __typename: "DeleteReply",
    id: string,
    channelId: string,
    inReplyToMessage: string,
    status: boolean,
  } | null,
};
