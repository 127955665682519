/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotified = /* GraphQL */ `
  subscription OnNotified($userId: ID!, $type: NotificationType) {
    onNotified(userId: $userId, type: $type) {
      ... on Liked {
        id
        type
        userId
        channelId
        messageId
        likedBy
        createdAt
      }
      ... on Replied {
        id
        type
        userId
        channelId
        messageId
        replyId
        repliedBy
        createdAt
      }
    }
  }
`;
export const onMessageByChannel = /* GraphQL */ `
  subscription OnMessageByChannel($channelId: ID!) {
    onMessageByChannel(channelId: $channelId) {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      replies
      type
      likesCount
      liked
    }
  }
`;
export const onMessage = /* GraphQL */ `
  subscription OnMessage {
    onMessage {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      replies
      type
      likesCount
      liked
    }
  }
`;
export const onEditMessageByChannel = /* GraphQL */ `
  subscription OnEditMessageByChannel($channelId: ID!) {
    onEditMessageByChannel(channelId: $channelId) {
      id
      channelId
      content
      createdAt
      updatedAt
      replies
      type
      likesCount
    }
  }
`;
export const onDeleteMessageByChannel = /* GraphQL */ `
  subscription OnDeleteMessageByChannel($channelId: ID!) {
    onDeleteMessageByChannel(channelId: $channelId) {
      id
      channelId
      status
    }
  }
`;
export const onLikeMessageByChannel = /* GraphQL */ `
  subscription OnLikeMessageByChannel($channelId: ID!) {
    onLikeMessageByChannel(channelId: $channelId) {
      id
      channelId
      inReplyToMessage
      likesCount
      status
    }
  }
`;
export const onUnLikeMessageByChannel = /* GraphQL */ `
  subscription OnUnLikeMessageByChannel($channelId: ID!) {
    onUnLikeMessageByChannel(channelId: $channelId) {
      id
      channelId
      inReplyToMessage
      likesCount
      status
    }
  }
`;
export const onReplyByMessage = /* GraphQL */ `
  subscription OnReplyByMessage($inReplyToMessage: ID!) {
    onReplyByMessage(inReplyToMessage: $inReplyToMessage) {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      inReplyToMessage
      type
      likesCount
      liked
    }
  }
`;
export const onReplyByChannel = /* GraphQL */ `
  subscription OnReplyByChannel($channelId: ID!) {
    onReplyByChannel(channelId: $channelId) {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      inReplyToMessage
      type
      likesCount
      liked
    }
  }
`;
export const onEditReplyByMessage = /* GraphQL */ `
  subscription OnEditReplyByMessage($inReplyToMessage: ID!) {
    onEditReplyByMessage(inReplyToMessage: $inReplyToMessage) {
      id
      channelId
      content
      createdAt
      updatedAt
      inReplyToMessage
      type
      likesCount
    }
  }
`;
export const onDeleteReplyByMessage = /* GraphQL */ `
  subscription OnDeleteReplyByMessage($inReplyToMessage: ID!) {
    onDeleteReplyByMessage(inReplyToMessage: $inReplyToMessage) {
      id
      channelId
      inReplyToMessage
      status
    }
  }
`;
