import Draft from 'draft-js';
import Immutable from 'immutable';
import styled, { css } from 'styled-components';
import { SvgClose } from 'components/Svgs/exchangesSvgs';

const {
  ContentBlock, genKey, EditorState,
} = Draft;
const { List, Map } = Immutable;

const DivMetaPreview = styled.div<any>`
  border: 1px solid #00000073;
  width: 80%;
  padding: 1vh;
  border-radius: 6px;
  margin-top: 0.5vh;
  user-select: ${({ view }) => (view ? 'auto' : 'none')};
  cursor: ${({ view }) => (view ? 'default' : 'pointer')};
  position: relative;
  z-index: 40;

  p {
    ${({ view }) => !view && css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
    font-size: var(--size-15);
  }
    `;
const title = css`
  font-weight: 600;
  color: var(--color-light-royal-blue);
  cursor: pointer;
  width: min-content;

  &:hover {
    color: var(--color-light-royal-blue);
    text-decoration: underline;
  }
`;

const close = css`
  width: 2vh;
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
`;

const description = css`
`;

export const MyCustomBlockEditor = (props) => {
  const { block, onClosePreview, onRedirect } = props;
  const data = block.getData().toJS();
  return (
    <DivMetaPreview
      contentEditable={false}
      onClick={() => console.log('CLICK BOX')}
    >
      {onClosePreview && (
        <div css={close} role="presentation" onClick={(e) => onClosePreview(e, props)}>
          <SvgClose />
        </div>
      )}
      <div role="presentation" onClick={() => onRedirect(data)} css={title}>
        {data?.title || 'TITLE'}
      </div>
      <p css={description}>
        {data?.description || 'DESCRIPTION'}
      </p>
    </DivMetaPreview>
  );
};

export const MyCustomBlockPreview = (props) => {
  const { block, onClosePreview } = props;
  const data = block.getData().toJS();

  return (
    <DivMetaPreview
      contentEditable={false}
      view
    >
      {onClosePreview && (
        <div css={close} role="presentation" onClick={(e) => onClosePreview(e, props)}>
          <SvgClose />
        </div>
      )}
      <a href={data?.url} target="_blank" css={title} rel="noreferrer">
        {data?.title || 'TITLE'}
      </a>
      <p css={description}>
        {data?.description || 'DESCRIPTION'}
      </p>
      {data.image && (
        <a href={data?.url} target="_blank" rel="noreferrer">
          <img src={data.image} alt={data.url} />
        </a>
      )}
    </DivMetaPreview>
  );
};

export const blockRendererFnView = (contentBlock, onClosePreview = null) => {
  const type = contentBlock.getType();

  if (type === 'MyCustomBlock') {
    return {
      component: (props) => <MyCustomBlockPreview {...props} onClosePreview={onClosePreview} />,
    };
  }
};

export const blockRendererFn = (contentBlock, onClosePreview = null, onRedirect = null) => {
  const type = contentBlock.getType();

  if (type === 'MyCustomBlock') {
    return {
      component: (props) => <MyCustomBlockEditor {...props} onClosePreview={onClosePreview} onRedirect={onRedirect} />,
    };
  }
};

export const addNewBlockAt = (
  editorState,
  pivotBlockKey,
  newBlockType = 'PREVIEW',
  initialData = Map({}),
) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(pivotBlockKey);

  if (!block) {
    throw new Error(`The pivot key - ${pivotBlockKey} is not present in blockMap.`);
  }

  const blocksBefore = blockMap.toSeq().takeUntil((v) => (v === block));
  const blocksAfter = blockMap.toSeq().skipUntil((v) => (v === block)).rest();
  const newBlockKey = genKey();

  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: newBlockType,
    text: '',
    characterList: List(),
    depth: 0,
    data: initialData,
    style: 'PREVIEW',
  });

  const newBlockMap = blocksBefore.concat(
    [[pivotBlockKey, block], [newBlockKey, newBlock]],
    blocksAfter,
  ).toOrderedMap();

  const selection = editorState.getSelection();

  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false,
    }),
  });

  return EditorState.push(editorState, newContent, 'split-block');
};
