import Image from 'next/image';
import {
  forwardRef,
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import {
  EditorState, convertFromRaw, Modifier, Editor as DraftEditor, CompositeDecorator,
  SelectionState,
} from 'draft-js';
import { OrderedSet } from 'immutable';
import { notification } from 'antd';
import { createPopup } from '@typeform/embed';
import { getPermanentUrl } from 'utils/url';
import { DivFlex } from 'styles/common';
import SC from './style';
import HoverTools from '../HoverTools';
import {
  mutationDeleteMessage, mutationDeleteReply, mutationLikeMessage, mutationUnLikeMessage,
} from 'containers/Exchanges/actions/exchanges';
import useExchanges from 'hooks/useExchanges';
import MyEditor from 'containers/Editor';
import { findLinkEntities, getLastKeyBeforePreview, moveSelectionToEnd } from 'containers/Editor/utils';
import ReadOnlyLink from 'components/UrlStyles/ReadOnlyLink';
import { blockRendererFnView } from 'containers/Editor/CustomBlock';
import useAuth from 'hooks/useAuth';
import { SvgLike } from 'components/Svgs/exchangesSvgs';

const monthsLabel = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
];

const roles = {
  coach: 'Formateur·ice',
  author: 'Auteur·e',
  moderator: 'Équipe Happyneuron',
};

const styleMap = {
  edited: {
    color: 'var(--color-gray-warm)',
    fontSize: 'var(--size-16)',
  },
};
interface MessageProps {
    message: any
    newDate?: boolean
    deleteMessage: any
    updateLike: any
    handleCurrentReply?: any
    currentChannel: any
    URLInfos: any
    setShowURLInput: any
    showURLInput: any
    setUrlValue: any
    urlValue: any
    currentReply: any
    setURLInfos: any
    mode?: string
    mergeMessage: boolean
    waitActionInTools: any
    setWaitActionInTools: any
    handleSubmitMessage: any
    anchor?: boolean
    sharedMode?: boolean
}

// eslint-disable-next-line react/display-name
const Message = forwardRef(({
  message, newDate, deleteMessage, updateLike, handleCurrentReply, currentChannel,
  setShowURLInput, showURLInput, setUrlValue, waitActionInTools, setWaitActionInTools,
  urlValue, currentReply, setURLInfos, URLInfos, mode, mergeMessage, handleSubmitMessage, anchor, sharedMode,
}: MessageProps, lastMessageRef: any) => {
  const [hovered, setHovered] = useState(false);
  const [editionMode, setEditionMode] = useState(false);
  const minutes = new Date(message.createdAt).getMinutes();
  const { state } = useExchanges();
  const { user } = useAuth();
  const { toggle } = createPopup('F3yFULuL', {
    tracking: {
      email: user?.email,
      firstname: user?.firstName,
      name: user?.lastName,
      message_id: `${window.location.origin}/echanges?messageId=${encodeURIComponent(message.id)}&channel=${encodeURIComponent(currentChannel.id)}`,
    },
  });
  const editorRef = useRef(null);

  const newBarRef = useRef(null);

  const contentState = convertFromRaw(JSON.parse(message.content));
  const tt = EditorState.createWithContent(contentState);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: ReadOnlyLink,
    },
  ]);
  const editorS = EditorState.set(tt, { decorator });
  const [editorState, setEditorState] = useState(editorS);
  const hasFullAccess = message?.profile?.id === state.user?.id || state.user?.role?.includes('moderator');

  const focusEditor = useCallback(() => {
    if (editorRef?.current) {
      editorRef.current.focus();
    }
  }, [editorRef]);

  useEffect(() => {
    const contentS = convertFromRaw(JSON.parse(message.content));
    const aa = EditorState.createWithContent(contentS);
    const edito = EditorState.set(aa, { decorator });
    setEditorState(edito);
  }, [message]);

  const handleLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (message.liked) {
      updateLike(message);
      await mutationUnLikeMessage({ messageId: message.id, channelId: message.channelId });
    } else {
      updateLike(message);
      await mutationLikeMessage({ messageId: message.id, channelId: message.channelId });
    }
  };

  const handleClick = async (e, method) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      switch (method) {
        case 'trash': {
          try {
            if (mode === 'reply') {
              deleteMessage(message, mode);
              await mutationDeleteReply({ ...message, messageId: message.inReplyToMessage });
            } else {
              deleteMessage(message);
              await mutationDeleteMessage(message);
            }
          } catch (error) {
            console.log({ error });
          }
          break;
        }
        case 'edit': {
          setEditorState(moveSelectionToEnd(editorState));
          setEditionMode(true);
          setTimeout(() => focusEditor(), 0);
          break;
        }
        case 'emoji': {
          handleLike(e);
          break;
        }
        case 'reply': {
          handleCurrentReply(message);
          break;
        }
        case 'share': {
          let sharedUrl = `${window.location.origin}/echanges?messageId=${encodeURIComponent(message.id)}&channel=${encodeURIComponent(currentChannel.id)}&shared=true`;
          if (message.type === 'Reply') {
            sharedUrl = `${window.location.origin}/echanges?messageId=${encodeURIComponent(message.inReplyToMessage)}&channel=${encodeURIComponent(currentChannel.id)}&replyId=${encodeURIComponent(message.id)}&shared=true`;
          }
          navigator.clipboard.writeText(sharedUrl);
          notification.success({
            message: 'Le lien vers le message a bien été copié',
          });
          break;
        }
        case 'warning': {
          toggle();
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log({ error, mode });
      notification.error({
        message: 'Veuillez nous excuser, une erreur est survenue.',
        description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
      });
    }
  };

  const isUpdated = message.updatedAt && message.createdAt !== message.updatedAt;
  let updatedEditor = editorState;

  if (isUpdated) {
    const currentContent = editorState.getCurrentContent();

    const blockMap = currentContent.getBlockMap();

    const lastGoodKey = getLastKeyBeforePreview(blockMap);

    const lastBlockWithoutPreview = currentContent.getBlockForKey(lastGoodKey);

    const length = lastBlockWithoutPreview.getLength();

    const selection = new SelectionState({
      anchorKey: lastGoodKey,
      anchorOffset: length,
      focusKey: lastGoodKey,
      focusOffset: length,
    });
    const textWithInsert = Modifier.insertText(currentContent, selection, '  (modifié)', OrderedSet.of('edited'));
    updatedEditor = EditorState.push(editorState, textWithInsert, 'insert-characters');
  }
  const today = new Date();

  const getFornattedDate = (date) => {
    if (date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()) return "Aujourd'hui";
    if (date.getDate() === today.getDate() - 1
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()) return 'Hier';
    return (
      <>
        {date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}
        {' '}
        {monthsLabel[date.getMonth()]}
      </>
    );
  };

  const DateHours = () => (
    <>
      {new Date(message.createdAt).getHours()}
      h
      {minutes < 10 ? `0${minutes}` : minutes}
    </>
  );

  const hasNewMessagesBar = !sharedMode && anchor
   && (currentChannel?.userRead?.unreadUserMessage || currentChannel?.userRead?.hasClicked);

  useLayoutEffect(() => {
    if (hasNewMessagesBar && newBarRef?.current) {
      newBarRef.current?.scrollIntoView();
    }
  }, []);

  return (
    <>
      <SC.Message
        onMouseEnter={() => !waitActionInTools && setHovered(true)}
        onMouseLeave={() => !waitActionInTools && setHovered(false)}
        key={message.id}
        hovered={hovered}
        anchor={anchor && sharedMode}
        ref={lastMessageRef}
        pointer={message.replies && mode !== 'reply'}
        onClick={(e) => message.replies && mode !== 'reply' && handleClick(e, 'reply')}
      >
        {hovered && (
        <HoverTools
          handleClick={handleClick}
          hasFullAccess={hasFullAccess}
          mode={mode}
          setWaitActionInTools={setWaitActionInTools}
        />
        )}

        {!mergeMessage && newDate && !hasNewMessagesBar && (
        <div css={SC.delimitation}>
          <div css={SC.delim1} />
          <SC.PDay mode={mode}>
            {getFornattedDate(new Date(message.createdAt))}
          </SC.PDay>

        </div>
        )}

        <DivFlex maxWidth="50vw" position="relative">

          {lastMessageRef && <div />}

          {mergeMessage && (
          <div css={SC.dateHover}>
            {hovered && <DateHours />}
          </div>
          )}
          <SC.DivPicture mergeMessage={mergeMessage}>
            {message?.profile?.picture ? (
              <div css={SC.profileImage}>
                <Image
                  css={SC.profileImage}
                  src={getPermanentUrl(message.profile.picture, '-67x67')}
                  alt="profile"
                  fill
                  sizes="100vw"
                />
              </div>
            ) : (
              <p css={SC.acrony}>
                {message.profile.name.split(' ').map((n) => n.slice(0, 1))}
              </p>
            )}

          </SC.DivPicture>
          <div css={SC.contentMessage}>
            {!mergeMessage && (
            <>
              <div css={SC.nameDate}>
                <p css={SC.name}>{message.profile.name}</p>
                <p css={SC.date}>
                  <DateHours />
                </p>
              </div>
              <p css={SC.roles}>
                {message.profile.role.map((el, i) => (i === 0 ? `${roles[el]}` : `, ${roles[el]}`))}
              </p>
            </>
            )}

            {editionMode ? (
              <MyEditor
                overrideEditorState={editorState}
                readOnly
                mode={mode === 'reply' ? 'edit-reply' : 'edit'}
                ref={editorRef}
                focusEditor={focusEditor}
                setShowURLInput={setShowURLInput}
                showURLInput={showURLInput}
                setUrlValue={setUrlValue}
                urlValue={urlValue}
                currentReply={currentReply}
                setURLInfos={setURLInfos}
                URLInfos={URLInfos}
                currentChannel={currentChannel}
                setEditionMode={setEditionMode}
                message={message}
                handleSubmitMessage={handleSubmitMessage}
              />
            ) : (
              <>
                <DraftEditor
                  customStyleMap={styleMap}
                  css={SC.content}
                  editorState={isUpdated ? updatedEditor : editorState}
                  onChange={setEditorState}
                  readOnly
                  blockRendererFn={(c) => blockRendererFnView(c)}
                />
              </>
            )}
          </div>

        </DivFlex>
        {message.likesCount ? (
          <SC.DivLiked liked={message.liked} type="button" onClick={handleLike}>
            <div css={SC.like}>
              <SvgLike fill />
            </div>
            <span>
              {message.likesCount}
            </span>
          </SC.DivLiked>
        ) : null}

        {message.replies ? (
          <SC.DivReplies disabled={mode === 'reply'} onClick={(e) => mode !== 'reply' && handleClick(e, 'reply')}>
            <span>
              {message.replies}
              {' '}
              réponse
              {message.replies > 1 && 's'}
            </span>
          </SC.DivReplies>
        ) : null}

      </SC.Message>

      {hasNewMessagesBar ? (
        <div ref={newBarRef} css={SC.delimitation}>
          <div css={SC.delimNew}>
            <SC.PDay mode={mode} hasNewMessagesBar>
              {getFornattedDate(new Date(message.createdAt))}
            </SC.PDay>
          </div>
          <SC.PDayNewMess mode={mode}>
            Nouveaux messages
          </SC.PDayNewMess>
        </div>
      ) : null}
    </>
  );
});

export default Message;
