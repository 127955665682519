import {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';

import { useRouter } from 'next/router';
import MyEditor from 'containers/Editor';
import Channels from 'containers/Channels';
import useEchanges from 'hooks/useExchanges';
import {
  queryListMessageByChannel, subscribeOnMessage,
  queryListReplyByMessage, subscribeOnEditMessage,
  subscribeOnDeleteMessage, subscribeOnLikeMessage,
  subscribeOnUnLikeMessage, subscribeOnReply, subscribeOnEditReply, subscribeOnDeleteReply,
  subscribeOnReplyByChannel,
  mutationMessage,
  mutationReply,
  mutationLastUserRead,
} from 'containers/Exchanges/actions/exchanges';
import { SvgLogoSmallLoader } from 'components/Svgs';
import useAuth from 'hooks/useAuth';
import Message from '../Message';
import SC from './style';
import Reserved from '../Reserved';
import { SvgClose } from 'components/Svgs/exchangesSvgs';
import { DivFlex } from 'styles/common';
import { IShowUrlInput } from '../types';
import { DirectionType } from 'API';
import useHistory from 'hooks/useHistory';
import { getListChannelsAct } from 'containers/Exchanges/actions';
import Button from 'components/Button';
import useOnScreen from 'containers/Exchanges/hooks/useOnScreen';
import useDatas from 'hooks/useDatas';

interface ExchangeHomeProps {
}

const ExchangeHome: React.FC<ExchangeHomeProps> = () => {
  const { query } = useHistory();
  const router = useRouter();
  const overrideToken = decodeURIComponent(query?.messageId || '' as string).replace('Message#', '') || null;
  const overrideTokenReply = decodeURIComponent(query?.replyId || '' as string).replace('Reply#', '') || null;

  const [waitActionInTools, setWaitActionInTools] = useState(false);
  const [nextToken, setNextToken] = useState(overrideToken);
  const [nextTokenReply, setNextTokenReply] = useState(overrideTokenReply);
  const [newNextToken, setNewNextToken] = useState(null);
  const [newNextTokenReply, setNewNextTokenReply] = useState(null);

  const [loadingPrev, setLoadingPrev] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);

  const [loadingPrevReply, setLoadingPrevReply] = useState(false);
  const [loadingNextReply, setLoadingNextReply] = useState(false);

  const tmpMessageId = useRef(null);

  const [listMessages, setListMessages] = useState(null);
  const [listMessagesReply, setListMessagesReply] = useState(null);

  const [showURLInput, setShowURLInput] = useState<IShowUrlInput>(null);
  const [URLInfos, setURLInfos] = useState();
  const [urlValue, setUrlValue] = useState({
    text: '',
    link: '',
  });

  const editorRef = useRef(null);
  const editorReplyRef = useRef(null);

  const lastMessageRef = useRef(null);
  const lastReplyRef = useRef(null);

  const lastTokenRef = useRef(null);
  const lastTokenReplyRef = useRef(null);

  const [currentReply, setCurrentReply] = useState(null);

  const { state: { channels, user: profile }, dispatch } = useEchanges();
  const { user, isSubscribed } = useAuth();
  const { trainingList, baughtTraining } = useDatas();

  const channelInfo = channels.find(({ id }) => id === query?.channel);
  const currentChannel = channelInfo || { id: query?.channel || null };

  const [displayNotifTopMore, setDisplayNotifTopMore] = useState(true);
  const [displayNotifBottomMore, setDisplayNotifBottomMore] = useState(true);

  const [removeNewMessageBar, setRemoveNewMessageBar] = useState(false);

  // const hasNewMessage = currentChannel?.userRead?.unreadUserMessage > 0 || currentChannel?.userRead?.hasClicked;

  useEffect(() => {
    const defaultChannel = channels.find((el) => el.categoryName === "monacademy#laviedel'academy");
    if (defaultChannel?.id && !currentChannel?.id) {
      router.push({ pathname: '/echanges', query: { channel: defaultChannel?.id } }, undefined, { shallow: true });
    }
  }, [channels?.length]);

  useEffect(() => {
    if (currentChannel?.id) {
      setNextToken(null);
      setListMessages(null);
      localStorage.setItem('channel', query.channel);
      dispatch({ type: 'RESET_UNREAD', payload: { channelId: currentChannel?.id } });
    }
  }, [currentChannel?.id]);

  const anchorRef = useRef(null);
  const anchorReplyRef = useRef(null);

  const findLastMessage = (messages) => {
    if (!messages?.length) return null;
    let lastMessage = null;
    messages.forEach((message) => {
      if (new Date(message.createdAt) > new Date(lastMessage)) {
        lastMessage = message;
      }
    });

    return lastMessage;
  };
  // console.log(findLastMessage(listMessages), channels.find(({ id }) => id === currentChannel?.id));

  useLayoutEffect(() => {
    if (!listMessages?.length) return undefined;
    // console.log(query?.channel, listMessages?.[0]);
    if (overrideToken && anchorRef.current) {
      anchorRef.current = false;
      lastMessageRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [listMessages?.length]);

  useLayoutEffect(() => {
    if (!listMessagesReply?.length) return undefined;
    if (overrideTokenReply && anchorReplyRef.current) {
      anchorReplyRef.current = false;
      lastReplyRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [listMessagesReply?.length]);

  const formattedChannels = [];
  channels.forEach((el) => {
    if (!formattedChannels[el.category]) {
      formattedChannels[el.category] = [el];
    } else formattedChannels[el.category].push(el);
  });

  const updateLastUserRead = async (messages) => {
    const lastMessageRead = findLastMessage(messages);
    if (lastMessageRead?.id) {
      await mutationLastUserRead({ messageId: lastMessageRead?.id, channelId: currentChannel?.id });
      getListChannelsAct(dispatch, currentChannel?.id);
    }
  };

  const handleNext = async (limit = 20) => {
    anchorRef.current = true;
    if (!newNextToken || loadingNext) return;
    setLoadingNext(true);
    lastMessageRef.current = null;
    const { data } = await queryListMessageByChannel({
      channelId: currentChannel.id, nextToken: newNextToken, limit, sortDirection: DirectionType.ASC,
    });
    lastTokenRef.current = newNextToken;
    setNewNextToken(data?.listMessageByChannel?.nextToken);
    const len = data?.listMessageByChannel?.messages.length;
    setListMessages((pS) => [...data?.listMessageByChannel?.messages.slice(1, len).reverse(), ...pS]);
    updateLastUserRead(data?.listMessageByChannel?.messages.slice(1, len).reverse());
    setLoadingNext(false);
  };

  const handleNextReply = async () => {
    anchorReplyRef.current = true;
    if (!newNextTokenReply || loadingNextReply) return;
    setLoadingNextReply(true);
    lastReplyRef.current = null;
    const { data } = await queryListReplyByMessage({
      messageId: currentReply.id, nextToken: newNextTokenReply, limit: 20, sortDirection: DirectionType.ASC,
    });
    lastTokenReplyRef.current = newNextTokenReply;
    setNewNextTokenReply(data?.listReplyByMessage?.nextToken);
    const len = data?.listReplyByMessage?.replies.length;
    setListMessagesReply((pS) => [...data?.listReplyByMessage?.replies.slice(1, len).reverse(), ...pS]);
    setLoadingNextReply(false);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const { data } = await queryListMessageByChannel({
        channelId: currentChannel?.id, nextToken: overrideToken || null, limit: 20, sortDirection: DirectionType.DESC,
      });
      setNextToken(data?.listMessageByChannel?.nextToken);
      lastTokenRef.current = nextToken;
      let messages = data?.listMessageByChannel?.messages;
      if (overrideToken) {
        anchorRef.current = true;
        const res = await queryListMessageByChannel({
          channelId: currentChannel?.id,
          nextToken: messages[0].id.replace('Message#', ''),
          limit: 20,
          sortDirection: DirectionType.ASC,
        });
        const len = res?.data?.listMessageByChannel?.messages.length;
        // setNewNextToken(null);
        setNewNextToken(res?.data?.listMessageByChannel?.messages[len - 1].id.replace('Message#', ''));
        messages = [...res?.data?.listMessageByChannel?.messages.slice(1, len).reverse(), ...messages];
      }
      setListMessages(messages);

      updateLastUserRead(messages);

      if (overrideTokenReply) {
        setCurrentReply(messages?.find((el) => el.id === `Message#${overrideToken}`));
      }
    };
    if (currentChannel?.id) {
      fetchMessages();
    }
    return () => {
      setListMessages(null);
      setNextToken(null);
      setNewNextToken(null);
      setNewNextTokenReply(null);
      setRemoveNewMessageBar(false);
    };
  }, [currentChannel?.id]);

  useEffect(() => {
    const fetchReply = async () => {
      const { data } = await queryListReplyByMessage({
        messageId: currentReply.id, nextToken: nextTokenReply, limit: 20, sortDirection: DirectionType.DESC,
      });
      setNextTokenReply(data?.listReplyByMessage?.nextToken);
      lastTokenReplyRef.current = nextTokenReply;
      let replies = data?.listReplyByMessage?.replies;
      if (overrideTokenReply) {
        anchorReplyRef.current = true;
        const res = await queryListReplyByMessage({
          messageId: currentReply?.id,
          nextToken: replies[0]?.id.replace('Reply#', ''),
          limit: 20,
          sortDirection: DirectionType.ASC,
        });
        const len = res?.data?.listReplyByMessage?.replies.length;
        setNewNextTokenReply(res?.data?.listReplyByMessage?.replies[len - 1]?.id?.replace('Reply#', ''));
        replies = [...res?.data?.listReplyByMessage?.replies.slice(1, len).reverse(), ...replies];
      }
      setListMessagesReply(replies);
    };
    if (currentReply) fetchReply();
    return () => {
      setNextTokenReply(null);
      setListMessagesReply(null);
    };
  }, [currentReply]);

  const onError = (error, name) => console.error({ error, name });

  useEffect(() => {
    let subscriptionMessage = null as any;
    let subscriptionEdit = null as any;
    let subscriptionDelete = null as any;
    let subscriptionLike = null as any;
    let subscriptionUnlike = null as any;
    let subscriptionOnReply = null as any;

    const onNextMessage = ({ value }) => {
      const { data } = value;

      updateLastUserRead([data.onMessage]);
      if (data.onMessage.profile.id !== profile.id) {
        if (data.onMessage.channelId === currentChannel?.id) {
          setListMessages((pS) => [{ ...data.onMessage }, ...pS]);
        } else {
          dispatch({ type: 'NEW_UNREAD', payload: { channelId: data.onMessage.channelId } });
        }
      }
    };

    const onNextEditMessage = ({ value }) => {
      const { data } = value;
      setListMessages((pS) => pS.map((el) => (el.id === data?.onEditMessageByChannel?.id
        ? { ...el, ...data?.onEditMessageByChannel }
        : el)));
    };
    const onNextDeleteMessage = ({ value }) => {
      const { data } = value;
      setListMessages((pS) => pS.filter((el) => (el.id !== data?.onDeleteMessageByChannel?.id)));
    };
    const onNextLikeMessage = ({ value }) => {
      const { data } = value;
      setListMessages((pS) => pS.map((el) => (el.id === data?.onLikeMessageByChannel?.id
        ? { ...el, ...data?.onLikeMessageByChannel }
        : el)));
    };
    const onNextUnLikeMessage = ({ value }) => {
      const { data } = value;
      setListMessages((pS) => pS.map((el) => (el.id === data?.onUnLikeMessageByChannel?.id
        ? { ...el, ...data?.onUnLikeMessageByChannel }
        : el)));
    };

    const onNextOnReplyChannel = ({ value }) => {
      const { data } = value;
      if (data.onReplyByChannel.profile.id !== profile.id) {
        setListMessages((pS) => pS.map((el) => (el.id === data.onReplyByChannel.inReplyToMessage
          ? { ...el, replies: el.replies + 1 } : el)));
      }
    };

    const subscribeMessage = async () => {
      subscriptionMessage = subscribeOnMessage(onNextMessage, (e) => onError(e, 'subscribeOnMessage'));
    };

    const subscribeEditMessage = async () => {
      subscriptionEdit = subscribeOnEditMessage(currentChannel?.id,
        onNextEditMessage, (e) => onError(e, 'subscribeOnEditMessage'));
    };
    const subscribeDeleteMessage = async () => {
      subscriptionDelete = subscribeOnDeleteMessage(currentChannel?.id,
        onNextDeleteMessage, (e) => onError(e, 'subscribeOnDeleteMessage'));
    };
    const subscribeLikeMessage = async () => {
      subscriptionLike = subscribeOnLikeMessage(currentChannel?.id,
        onNextLikeMessage, (e) => onError(e, 'subscribeOnLikeMessage'));
    };
    const subscribeUnLikeMessage = async () => {
      subscriptionUnlike = subscribeOnUnLikeMessage(currentChannel?.id,
        onNextUnLikeMessage, (e) => onError(e, 'subscribeOnUnLikeMessage'));
    };

    const subscribeOnReplyChannel = async () => {
      subscriptionOnReply = subscribeOnReplyByChannel(currentChannel?.id,
        onNextOnReplyChannel, (e) => onError(e, 'subscribeOnReplyByChannel'));
    };
    if (currentChannel?.id) {
      subscribeMessage();
      subscribeEditMessage();
      subscribeDeleteMessage();
      subscribeLikeMessage();
      subscribeUnLikeMessage();
      subscribeOnReplyChannel();
    }
    return () => {
      subscriptionMessage?.unsubscribe?.();
      subscriptionEdit?.unsubscribe?.();
      subscriptionDelete?.unsubscribe?.();
      subscriptionLike?.unsubscribe?.();
      subscriptionUnlike?.unsubscribe?.();
      subscriptionOnReply?.unsubscribe?.();
    };
  }, [currentChannel?.id]);

  useEffect(() => {
    let subscriptionReply = null as any;
    let subscriptionEditReply = null as any;
    let subscriptionDeleteReply = null as any;

    const onNextReply = ({ value }) => {
      const { data } = value;
      if (data.onReplyByMessage.profile.id === profile.id) {
        setListMessagesReply((pS) => pS.map((el) => (el.id === tmpMessageId?.current
          ? { ...data.onReplyByMessage }
          : el)));

        tmpMessageId.current = null;
      } else {
        setListMessagesReply((pS) => [{ ...data.onReplyByMessage }, ...pS]);
      }
    };

    const onNextEditReply = ({ value }) => {
      const { data } = value;
      setListMessagesReply((pS) => pS.map((el) => (el.id === data?.onEditReplyByMessage?.id
        ? { ...el, ...data?.onEditReplyByMessage }
        : el)));
    };

    const onNextDeleteReply = ({ value }) => {
      const { data } = value;

      setListMessagesReply((pS) => pS.filter((el) => (el.id !== data?.onDeleteReplyByMessage?.id)));
      setListMessages((pS) => pS.map((el) => (el.id === data?.onDeleteReplyByMessage?.inReplyToMessage
        ? { ...el, replies: el.replies - 1 } : el)));
    };

    const subscribeReply = async () => {
      subscriptionReply = subscribeOnReply(currentReply?.id,
        onNextReply, (e) => onError(e, 'subscribeOnReply'));
    };

    const subscribeEditReply = async () => {
      subscriptionEditReply = subscribeOnEditReply(currentReply?.id,
        onNextEditReply, (e) => onError(e, 'subscribeOnEditReply'));
    };

    const subscribDeleteReply = async () => {
      subscriptionDeleteReply = subscribeOnDeleteReply(currentReply?.id,
        onNextDeleteReply, (e) => onError(e, 'subscribeOnDeleteReply'));
    };

    if (currentReply?.id) {
      subscribeReply();
      subscribeEditReply();
      subscribDeleteReply();
    }

    return () => {
      if (subscriptionReply) subscriptionReply?.unsubscribe();
      if (subscriptionEditReply) subscriptionEditReply?.unsubscribe();
      if (subscriptionDeleteReply) subscriptionDeleteReply?.unsubscribe();
    };
  }, [currentReply?.id]);

  const handlePrev = async () => {
    if (nextToken && !loadingPrev) {
      setLoadingPrev(true);
      const { data } = await queryListMessageByChannel({
        channelId: currentChannel.id, nextToken, limit: 20, sortDirection: DirectionType.DESC,
      });
      lastTokenRef.current = nextToken;
      setNextToken(data?.listMessageByChannel?.nextToken);
      const len = data?.listMessageByChannel?.messages.length;
      setListMessages((pS) => [...pS, ...data?.listMessageByChannel?.messages.slice(1, len)]);
      setLoadingPrev(false);
    }
  };

  const handlePrevReply = async () => {
    if (nextTokenReply && !loadingPrevReply) {
      setLoadingPrevReply(true);
      const { data } = await queryListReplyByMessage({
        messageId: currentReply.id, nextToken: nextTokenReply, limit: 20, sortDirection: DirectionType.DESC,
      });
      lastTokenReplyRef.current = nextTokenReply;
      setNextTokenReply(data?.listReplyByMessage?.nextToken);
      const len = data?.listReplyByMessage?.replies.length;
      setListMessagesReply((pS) => [...pS, ...data?.listReplyByMessage?.replies.slice(1, len)]);
      setLoadingPrevReply(false);
    }
  };

  const deleteMessage = ({ id }, mode) => {
    if (mode === 'reply') {
      setListMessagesReply((pS) => pS.filter((el) => (el.id !== id)));
    } else {
      setListMessages((pS) => pS.filter((el) => (el.id !== id)));
    }
  };

  const updateLike = ({ id, liked }) => {
    const updatedMessages = listMessages.map((mess) => (mess.id === id ? ({
      ...mess,
      liked: !liked,
      likesCount: liked ? mess.likesCount - 1 : mess.likesCount + 1,
    }) : mess));
    setListMessages(updatedMessages);
  };

  const updateLikeReply = ({ id, liked }) => {
    const updatedMessages = listMessagesReply.map((mess) => (mess.id === id ? ({
      ...mess,
      liked: !liked,
      likesCount: liked ? mess.likesCount - 1 : mess.likesCount + 1,
    }) : mess));
    setListMessagesReply(updatedMessages);
  };

  const focusEditor = useCallback(() => {
    if (editorRef?.current) {
      editorRef.current.focus();
    }
  }, [editorRef]);

  const focusReplyEditor = useCallback(() => {
    if (editorReplyRef?.current) {
      editorReplyRef.current.focus();
    }
  }, [editorReplyRef]);
  const handleCurrentReply = (mess) => {
    setNextTokenReply(null);
    setListMessagesReply(null);
    setCurrentReply(mess?.id === currentReply?.id ? null : mess);
    setTimeout(() => focusReplyEditor(), 0);
  };

  const itemsRef = useRef([]);

  const onSubmitMessage = async (mode, content, messageId) => {
    const id = Date.now();
    tmpMessageId.current = id;
    switch (mode) {
      case 'create': {
        if (newNextToken) {
          await handleNext(1000);
        }
        setListMessages((pS) => [{
          channelId: currentChannel.id, content, profile, type: 'Message', id, createdAt: new Date(),
        }, ...pS]);
        setTimeout(() => focusEditor(), 0);
        const res = await mutationMessage({
          channelId: currentChannel.id,
          content,
        });
        setListMessages((pS) => pS.map((el) => (el.id === id ? ({ ...res?.data?.message }) : el)));
        setRemoveNewMessageBar(true);
        lastMessageRef.current = null;
        anchorRef.current = null;

        const block = document.getElementById('scrollableDiv');
        block.scrollTop = 0;
        break;
      }
      case 'reply': {
        setListMessagesReply((pS) => [{
          channelId: currentReply.channelId, content, profile, type: 'Reply', id, createdAt: new Date(),
        }, ...pS]);
        setListMessages((pS) => pS.map((el) => (el.id === messageId
          ? { ...el, replies: el.replies + 1 } : el)));
        const res = await mutationReply({
          messageId: currentReply.id,
          channelId: currentReply?.channelId,
          content,
        });
        setListMessagesReply((pS) => pS.map((el) => (el.id === id ? ({ ...res?.data?.reply }) : el)));
        setTimeout(() => focusReplyEditor(), 0);
        break;
      }
      case 'edit': {
        setListMessages((pS) => pS.map((el) => (el.id === messageId
          ? { ...el, content }
          : el)));
        break;
      }
      case 'edit-reply': {
        setListMessagesReply((pS) => pS.map((el) => (el.id === messageId
          ? { ...el, content }
          : el)));
        break;
      }
      default:
        break;
    }
  };

  const handleScroll = ({ target }) => {
    const isBottom = Math.abs(target.scrollTop) <= 3;
    const isTop = Math.abs(target.scrollTop) + target.clientHeight >= target.scrollHeight - 3;
    if (isTop) handlePrev();
    if (isBottom) handleNext();
  };

  const handleScrollReply = ({ target }) => {
    const isBottom = Math.abs(target.scrollTop) <= 3;
    const isTop = Math.abs(target.scrollTop) + target.clientHeight >= target.scrollHeight - 3;
    if (isTop) handlePrevReply();
    if (isBottom) handleNextReply();
  };

  const isFirstVisible = useOnScreen(itemsRef, 0, currentChannel?.id, channels);
  const isLastVisible = useOnScreen(itemsRef, itemsRef?.current?.length - 1, currentChannel?.id, channels);

  useEffect(() => {
    const element = document.getElementById('listChannels');
    if (!itemsRef.current.length) {
      setDisplayNotifTopMore(false);
      setDisplayNotifBottomMore(false);
      return undefined;
    }
    const handleScrollNotif = () => {
      const { top: topFirst } = itemsRef.current[0].getBoundingClientRect();
      const { top: topLast } = itemsRef.current[itemsRef?.current?.length - 1].getBoundingClientRect();
      if (topFirst >= element.scrollTop) setDisplayNotifTopMore(false);
      else if (!isFirstVisible) setDisplayNotifTopMore(true);
      if (topLast <= element.scrollTop) setDisplayNotifBottomMore(false);
      else if (!isLastVisible) setDisplayNotifBottomMore(true);
      // console.log(element.scrollTop, topFirst, topLast, isFirstVisible, isLastVisible);
    };
    handleScrollNotif();
    element.addEventListener('scroll', handleScrollNotif);
    return () => element.removeEventListener('scroll', handleScrollNotif);
  }, [currentChannel?.id, channels, isFirstVisible, isLastVisible]);

  const hasAccess = (el) => {
    if (el.category === 'Se Former') {
      const isFormateur = trainingList?.find(({ id }) => id === el.trainingHnaId)
        ?.coaches?.find(({ id }) => id === user?.id);

      return baughtTraining?.find((b) => b.id === el.trainingHnaId)
      || profile?.role?.includes('moderator')
      || isFormateur !== undefined;
    }
    return true;
  };

  const currentChannelExchange = channels?.find((channel) => channel.id === currentChannel?.id);
  return (
    <div css={SC.echangerHome}>
      {(URLInfos || showURLInput) && (
      <div css={SC.fadeBackground} />
      )}

      <div
        css={SC.listChannelsFixed}
      >
        {!isFirstVisible && displayNotifTopMore && itemsRef?.current?.length > 1 ? (
          <Button
            css={SC.customButtonChannel}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              itemsRef.current[0].scrollIntoView({
                behavior: 'smooth',
              });
            }}
            value="Plus de messages non lus"
            arrow="down"
          />
        ) : null}

        {!isLastVisible && displayNotifBottomMore && itemsRef?.current?.length > 0 ? (
          <Button
            css={SC.customButtonChannelBottom}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              itemsRef.current[itemsRef?.current?.length - 1].scrollIntoView({
                behavior: 'smooth',
              });
            }}
            value="Plus de messages non lus"
            arrow="up"
          />
        ) : null}

        <div
          css={SC.listChannels}
          id="listChannels"
          className="scrollbar-fixed"
        >
          <Channels
            ref={itemsRef}
            currentChannel={currentChannel}
            formattedChannels={formattedChannels}
          />

        </div>

      </div>
      {currentChannelExchange?.premium && !isSubscribed && !hasAccess(currentChannelExchange) ? (
        <Reserved user={user} />
      ) : (
        <>
          <div css={SC.home} id="messaging">
            <p css={SC.channelName}>{channels.find((el) => el.id === currentChannel?.id)?.name}</p>
            <div css={SC.messages} id="scrollableDiv" className="scrollbar-fixed" onScroll={handleScroll}>
              {loadingNext && (
              <div css={SC.miniLoader}>
                <SvgLogoSmallLoader />
              </div>
              )}

              {!nextToken && listMessages?.length === 0 ? (
                <p css={SC.placeholder}>
                  Soyez la première personne à écrire un message
                </p>
              ) : null}

              {listMessages?.map((message, i) => {
                const date = new Date(message.createdAt);
                const lastDate = new Date(listMessages[i + 1]?.createdAt);

                const newDate = lastDate.getDate() < date.getDate() || lastDate.getMonth() < date.getMonth()
                || lastDate.getFullYear() < date.getFullYear() || Number.isNaN(lastDate.getDate());
                const mergeMessage = !newDate && listMessages[i + 1]?.profile?.id === message.profile.id;
                return (
                  <Message
                    key={message.id}
                    deleteMessage={deleteMessage}
                    updateLike={updateLike}
                    handleCurrentReply={handleCurrentReply}
                    message={message}
                    newDate={newDate}
                    URLInfos={URLInfos}
                    setShowURLInput={setShowURLInput}
                    showURLInput={showURLInput}
                    setUrlValue={setUrlValue}
                    urlValue={urlValue}
                    currentChannel={currentChannel}
                    currentReply={currentReply}
                    setURLInfos={setURLInfos}
                    mergeMessage={mergeMessage}
                    waitActionInTools={waitActionInTools}
                    setWaitActionInTools={setWaitActionInTools}
                    handleSubmitMessage={onSubmitMessage}
                    anchor={listMessages[i + 1]?.id === `Message#${overrideToken}` && !removeNewMessageBar}
                    ref={message.id === `Message#${lastTokenRef.current}` ? lastMessageRef : null}
                    sharedMode={query?.shared}
                  />
                );
              })}

              <div css={SC.miniLoader}>
                {(loadingPrev || !listMessages) && <SvgLogoSmallLoader />}
              </div>
            </div>

            <div css={SC.editorMain}>
              {listMessages !== undefined && listMessages?.[0]?.channelId === currentChannel?.id && listMessages?.length && currentChannel?.userRead?.unreadUserMessage > 0
                && newNextToken ? (
                  <Button
                    css={SC.customButton}
                    onClick={() => handleNext()}
                    value="Plus de messages non lus"
                    arrow="up"
                  />
                ) : null}
              <MyEditor
                mode="create"
                ref={editorRef}
                focusEditor={focusEditor}
                setShowURLInput={setShowURLInput}
                showURLInput={showURLInput}
                setUrlValue={setUrlValue}
                urlValue={urlValue}
                currentChannel={currentChannel}
                currentReply={currentReply}
                setURLInfos={setURLInfos}
                URLInfos={URLInfos}
                handleSubmitMessage={onSubmitMessage}
              />
            </div>
          </div>
        </>
      )}

      {currentReply && (
        <div css={SC.replyMessage}>
          <div css={SC.replyHead}>
            <DivFlex align="center">
              <p css={SC.replyTitle}>FIL DE DISCUSSION</p>
              {/* <p css={SC.replyTitleName}>{currentReply?.channelId}</p> */}
            </DivFlex>
            <div css={SC.closeReply} onClick={() => handleCurrentReply(null)} role="presentation">
              <SvgClose />
            </div>
          </div>

          <div css={SC.messages} id="scrollableDivReply" className="scrollbar-fixed" onScroll={handleScrollReply}>

            {loadingNextReply && (
            <div css={SC.miniLoader}>
              <SvgLogoSmallLoader />
            </div>
            )}

            {listMessagesReply?.map((message, i) => {
              const date = new Date(message.createdAt).getDate();
              const lastDate = new Date(listMessagesReply[i + 1]?.createdAt).getDate();

              const newDate = lastDate < date || (i === listMessagesReply.length - 1);
              const mergeMessage = !newDate && listMessagesReply[i + 1]?.profile?.id === message.profile.id;
              return (
                <Message
                  mode="reply"
                  key={message.id}
                  deleteMessage={deleteMessage}
                  updateLike={updateLikeReply}
                  message={message}
                  newDate={newDate}
                  URLInfos={URLInfos}
                  setShowURLInput={setShowURLInput}
                  showURLInput={showURLInput}
                  setUrlValue={setUrlValue}
                  urlValue={urlValue}
                  currentChannel={currentChannel}
                  currentReply={currentReply}
                  setURLInfos={setURLInfos}
                  mergeMessage={mergeMessage}
                  waitActionInTools={waitActionInTools}
                  setWaitActionInTools={setWaitActionInTools}
                  handleSubmitMessage={onSubmitMessage}
                  anchor={message.id === `Reply#${overrideTokenReply}`}
                  ref={message.id === `Reply#${lastTokenReplyRef.current}` ? lastReplyRef : null}
                  sharedMode={query?.shared}
                />
              );
            })}

            {listMessagesReply && currentReply?.content && !nextTokenReply ? (
              <Message
                mode="reply"
                key={currentReply.id}
                deleteMessage={deleteMessage}
                updateLike={updateLikeReply}
                message={currentReply}
                URLInfos={URLInfos}
                setShowURLInput={setShowURLInput}
                showURLInput={showURLInput}
                setUrlValue={setUrlValue}
                urlValue={urlValue}
                currentChannel={currentChannel}
                currentReply={currentReply}
                setURLInfos={setURLInfos}
                mergeMessage={false}
                waitActionInTools={waitActionInTools}
                setWaitActionInTools={setWaitActionInTools}
                handleSubmitMessage={onSubmitMessage}
              />
            ) : (
              <div css={SC.miniLoader}>
                <SvgLogoSmallLoader />
              </div>
            )}
            {!nextTokenReply && listMessagesReply?.length === 0 ? (
              <p css={SC.placeholder}>
                Il n'y a pas encore de discussion associée à ce message, démarrez un fil de discussion en inscrivant votre réponse ci-dessous !
              </p>
            ) : null}
          </div>

          <div css={SC.editorReply}>
            <MyEditor
              mode="reply"
              ref={editorReplyRef}
              focusEditor={focusReplyEditor}
              setShowURLInput={setShowURLInput}
              showURLInput={showURLInput}
              setUrlValue={setUrlValue}
              urlValue={urlValue}
              setURLInfos={setURLInfos}
              URLInfos={URLInfos}
              currentChannel={currentChannel}
              currentReply={currentReply}
              handleSubmitMessage={onSubmitMessage}
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default ExchangeHome;
