import { css } from 'styled-components';

const anchorUrl = css`
  color: var(--color-light-royal-blue);

  &:hover {
    color: var(--color-light-royal-blue);
    text-decoration: underline;
  }
`;

const ReadOnlyLink = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  const formattedUrl = url.replace('http://', '').replace('https://', '');
  return (
    <a css={anchorUrl} href={`//${formattedUrl}`} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default ReadOnlyLink;
