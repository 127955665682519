/* eslint-disable max-len */

export const SvgArrowUp = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z" fill="white" />
  </svg>

);

export const SvgLike = ({ fill = false }) => (
  <svg data-name="Composant 80 – 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38.723 35.803">
    <defs>
      <clipPath id="vpj7zq0oma">
        <path data-name="Rectangle 10455" style={{ fill: 'red', stroke: '#cbcbcb' }} d="M0 0h38.723v35.803H0z" />
      </clipPath>
    </defs>
    <g data-name="Groupe 9271">
      <g data-name="Groupe 8811" style={{ clipPath: 'url(#vpj7zq0oma)' }}>
        <path data-name="Tracé 10143" d="M27.91 15.024a2.743 2.743 0 0 0-.28-1.193c-1.169-3.678-6.642-3.409-14.426-3.588-1.3-.03-.557-1.529-.1-4.818C13.4 3.285 11.986 0 9.609 0 5.688 0 9.46 3.016 5.993 10.474 4.14 14.459 0 12.227 0 16.23v9.111c0 1.558.157 3.056 2.4 3.3 2.178.239 1.688 1.753 4.831 1.753h15.733a2.873 2.873 0 0 0 2.905-2.834 2.763 2.763 0 0 0-.609-1.715 2.816 2.816 0 0 0 1.5-2.465 2.764 2.764 0 0 0-.606-1.713 2.773 2.773 0 0 0 .666-4.451 2.791 2.791 0 0 0 1.09-2.194" transform="translate(5.406 2.703)" style={{ fill: fill ? '#ffdb5e' : 'none', stroke: fill ? '#ffdb5e' : '#969696', strokeWidth: '2px' }} />
        <path data-name="Tracé 10699" d="M30.261 28.642h7.5a2.934 2.934 0 0 0 2.5-1.388.734.734 0 0 0-.267-1.019.774.774 0 0 0-1.045.261 1.392 1.392 0 0 1-1.186.659H30.08a1.346 1.346 0 1 1 0-2.691h5.131a.744.744 0 1 0 0-1.488h-5.132a2.873 2.873 0 0 0-2.905 2.833 2.79 2.79 0 0 0 1.05 2.162 2.761 2.761 0 0 0 .187 4.184 2.778 2.778 0 0 0 .365 4.318 2.771 2.771 0 0 0-.742 1.874 2.873 2.873 0 0 0 2.905 2.833h4.78a2.935 2.935 0 0 0 2.5-1.387.734.734 0 0 0-.266-1.019.775.775 0 0 0-1.045.259 1.4 1.4 0 0 1-1.189.66h-4.78a1.346 1.346 0 1 1 0-2.691h5.671a2.934 2.934 0 0 0 2.5-1.388.733.733 0 0 0-.266-1.019.769.769 0 0 0-1.045.259 1.378 1.378 0 0 1-1.189.661h-6.157a1.347 1.347 0 1 1 0-2.692h7.053a2.935 2.935 0 0 0 2.5-1.387.733.733 0 0 0-.266-1.019.774.774 0 0 0-1.045.259 1.378 1.378 0 0 1-1.188.66h-7.246a1.347 1.347 0 1 1 0-2.692" transform="translate(-7.049 -8.083)" style={{ fill: fill ? '#ee9547' : '#969696' }} />
      </g>
    </g>
  </svg>
);

export const SvgWarning = () => (
  <svg data-name="Composant 82 – 2" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 26">
    <g data-name="Groupe 8806">
      <g data-name="Polygone 18" style={{ fill: 'none' }}>
        <path d="M13.268 3a2 2 0 0 1 3.465 0L28.27 23a2 2 0 0 1-1.732 3H3.463a2 2 0 0 1-1.733-3z" style={{ stroke: 'none' }} />
        <path d="M15 4.002 3.463 24h23.074L15 4.002m0-2c.674 0 1.348.334 1.732 1L28.27 23.002c.769 1.333-.193 2.999-1.733 2.999H3.463c-1.54 0-2.502-1.666-1.733-3L13.268 3.004c.384-.667 1.058-1 1.732-1z" style={{ fill: '#969696', stroke: 'none' }} />
      </g>
      <text
        data-name="!"
        transform="translate(12 21)"
        style={{
          fill: '#969696', fontSize: '17px', fontFamily: 'SourceSansPro-Bold,Source Sans Pro', fontWeight: 700,
        }}
      >
        <tspan x="0" y="0">!</tspan>
      </text>
    </g>
  </svg>
);

export const SvgShare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.542 29.496">
    <g data-name="Groupe 9273">
      <path data-name="Icon material-content-copy" d="M21.452 1.5H5.636A2.644 2.644 0 0 0 3 4.136v18.452h2.636V4.136h15.816zm3.954 5.272h-14.5a2.644 2.644 0 0 0-2.634 2.636V27.86a2.644 2.644 0 0 0 2.636 2.64h14.5a2.644 2.644 0 0 0 2.636-2.636V9.408a2.644 2.644 0 0 0-2.638-2.636zm0 21.088h-14.5V9.408h14.5z" transform="translate(-2.75 -1.25)" style={{ fill: '#969696', stroke: '#fff', strokeWidth: '.5px' }} />
      <path data-name="Icon metro-link" d="M7.477 8.905a.55.55 0 0 1-.39-.162 2.591 2.591 0 0 1 0-3.66l2.036-2.035a2.588 2.588 0 1 1 3.66 3.66l-.931.931a.552.552 0 0 1-.78-.78L12 5.927a1.485 1.485 0 0 0-2.1-2.1L7.866 5.864a1.487 1.487 0 0 0 0 2.1.552.552 0 0 1-.39.942zM5.522 12.9a2.588 2.588 0 0 1-1.83-4.418l.931-.931a.552.552 0 1 1 .78.78l-.931.931a1.485 1.485 0 0 0 2.1 2.1l2.037-2.04a1.487 1.487 0 0 0 0-2.1.552.552 0 1 1 .78-.78 2.591 2.591 0 0 1 0 3.66l-2.037 2.036a2.571 2.571 0 0 1-1.83.758z" transform="translate(6.88 10.152)" style={{ fill: '#878787' }} />
    </g>
  </svg>

);

export const SvgEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32.007 32.007">
    <g data-name="Composant 84 – 1">
      <path data-name="Icon metro-pencil" d="M28.6 1.928a4.82 4.82 0 0 1 3.855 7.712l-1.928 1.928-6.748-6.748 1.928-1.928a4.8 4.8 0 0 1 2.893-.964zM4.5 24.1l-1.929 8.677 8.676-1.928 17.834-17.835-6.748-6.748zm19.628-11.219-13.5 13.5-1.658-1.665 13.5-13.5 1.662 1.662z" transform="translate(-1.912 -1.428)" style={{ fill: '#969696', stroke: '#fff' }} />
    </g>
  </svg>
);

export const SvgEmoji = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20.607 20.607">
    <g id="Groupe_9274" data-name="Groupe 9274" transform="translate(-721.125 -578.924)">
      <path id="Ellipse_4933" data-name="Ellipse 4933" d="M10.3,2a8.3,8.3,0,1,0,8.3,8.3A8.313,8.313,0,0,0,10.3,2m0-2A10.3,10.3,0,1,1,0,10.3,10.3,10.3,0,0,1,10.3,0Z" transform="translate(721.125 578.924)" fill="#969696" />
      <path id="Tracé_10136" data-name="Tracé 10136" d="M804.787,314.028a1.334,1.334,0,1,0,1.334,1.334,1.334,1.334,0,0,0-1.334-1.334" transform="translate(-76.492 272.531)" fill="#969696" />
      <path id="Tracé_10137" data-name="Tracé 10137" d="M804.787,314.028a1.334,1.334,0,1,0,1.334,1.334,1.334,1.334,0,0,0-1.334-1.334" transform="translate(-70.226 272.531)" fill="#969696" />
      <path id="Soustraction_14" data-name="Soustraction 14" d="M5.72,3.13A6.767,6.767,0,0,1,0,0H11.44A6.766,6.766,0,0,1,5.72,3.13Z" transform="translate(725.709 591.842)" fill="#969696" />
    </g>
  </svg>

);

export const SvgReply = () => (
  <svg data-name="Composant 81 – 4" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 45.496 43.496">
    <defs>
      <clipPath id="olmk2t8gwa">
        <path data-name="Rectangle 10452" transform="translate(-3 -4)" style={{ fill: '#969696' }} d="M0 0h45.496v43.496H0z" />
      </clipPath>
    </defs>
    <g data-name="Groupe 8804">
      <g data-name="Groupe 8803" style={{ clipPath: 'url(#olmk2t8gwa)' }} transform="translate(3 4)">
        <path data-name="Tracé 10131" d="m23.969 406.171-3.055-3.943a.521.521 0 0 0-.411-.2H3.57a2.568 2.568 0 0 1-2.514-2.614v-12.273a2.568 2.568 0 0 1 2.514-2.614h9.907v-1.1H3.57A3.647 3.647 0 0 0 0 387.141v12.268a3.647 3.647 0 0 0 3.57 3.712h16.68l3.836 4.951a.521.521 0 0 0 .411.2.509.509 0 0 0 .176-.031.548.548 0 0 0 .352-.518v-14.217h-1.056z" transform="translate(0 -372.781)" style={{ stroke: '#969696', fill: '#969696' }} />
        <path data-name="Tracé 10132" d="M563.247 0h-17.885a3.647 3.647 0 0 0-3.57 3.712V24.3a.548.548 0 0 0 .352.518.51.51 0 0 0 .176.031.521.521 0 0 0 .411-.2l3.836-4.951h16.68a3.647 3.647 0 0 0 3.57-3.712V3.715A3.647 3.647 0 0 0 563.247 0m2.514 15.98a2.568 2.568 0 0 1-2.514 2.614h-16.933a.52.52 0 0 0-.411.2l-3.055 3.943V3.715a2.568 2.568 0 0 1 2.514-2.615h17.885a2.568 2.568 0 0 1 2.514 2.614z" transform="translate(-527.322 -.003)" style={{ stroke: '#969696', fill: '#969696' }} />
        <path data-name="Tracé 10133" d="M804.678 314.028a1.274 1.274 0 1 0 1.222 1.272 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-781.994 -305.308)" style={{ fill: '#969696' }} />
        <path data-name="Tracé 10134" d="M971.674 314.028a1.274 1.274 0 1 0 1.226 1.272 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-944.53 -305.308)" style={{ fill: '#969696' }} />
        <path data-name="Tracé 10135" d="M1138.672 314.028a1.274 1.274 0 1 0 1.225 1.273 1.25 1.25 0 0 0-1.225-1.273" transform="translate(-1107.068 -305.308)" style={{ fill: '#969696' }} />
      </g>
    </g>
  </svg>

);

export const SvgTrash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.981 28.645">
    <g data-name="Composant 83 – 1">
      <g data-name="Icon feather-trash-2">
        <path
          data-name="Tracé 3124"
          d="M4.5 9h23.981"
          transform="translate(-3.5 -2.521)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3125"
          d="M26 8.329v18.652a2.654 2.654 0 0 1-2.643 2.665H10.143A2.654 2.654 0 0 1 7.5 26.981V8.329m3.965 0V5.665A2.654 2.654 0 0 1 14.108 3h5.286a2.654 2.654 0 0 1 2.643 2.665v2.664"
          transform="translate(-3.761 -2)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3126"
          d="M15 16.5v8.218"
          transform="translate(-4.674 -3.654)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
        <path
          data-name="Tracé 3127"
          d="M21 16.5v8.218"
          transform="translate(-5.345 -3.654)"
          style={{
            fill: 'none', stroke: '#fb6565', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px',
          }}
        />
      </g>
    </g>
  </svg>
);

export const SvgBold = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12.513 13.734">
    <path id="Tracé_10693" data-name="Tracé 10693" d="M6.428,6.638a1.265,1.265,0,0,0,.992-.381A1.25,1.25,0,0,0,7.763,5.4a1.3,1.3,0,0,0-.314-.916,1.327,1.327,0,0,0-1.021-.343H4.272v2.5Zm.038,5.055a2.237,2.237,0,0,0,1.287-.334,1.134,1.134,0,0,0,.486-1,1.109,1.109,0,0,0-.486-.992,2.283,2.283,0,0,0-1.287-.325H4.272v2.652ZM0,3.662A2.762,2.762,0,0,1,.61,1.668,2.55,2.55,0,0,1,2.5,1.049H7.057a8.066,8.066,0,0,1,2.327.286,4.189,4.189,0,0,1,1.5.763,2.69,2.69,0,0,1,.8,1.107,3.683,3.683,0,0,1,.238,1.316,3.562,3.562,0,0,1-.429,1.822,2.336,2.336,0,0,1-1.516,1.02V7.4a2.812,2.812,0,0,1,1.9,1.144,3.737,3.737,0,0,1,.639,2.194,4.022,4.022,0,0,1-.314,1.583,3.471,3.471,0,0,1-.944,1.287,4.569,4.569,0,0,1-1.6.858,7.46,7.46,0,0,1-2.289.315H2.5a2.548,2.548,0,0,1-1.888-.62A2.76,2.76,0,0,1,0,12.169Z" transform="translate(0 -1.049)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgItalic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 7.269 13.671">
    <path id="Tracé_10696" data-name="Tracé 10696" d="M202.2,13.419c-.042.222-.085.448-.128.673a.576.576,0,0,0,.566.682h3.985a.571.571,0,0,0,.565-.467c.044-.229.085-.463.123-.693a.573.573,0,0,0-.541-.667l-.728-.034L207.8,3.06c0-.064,0-.105.064-.105l.78-.04a.568.568,0,0,0,.533-.457c.046-.22.091-.446.135-.669a.575.575,0,0,0-.565-.685H204.8a.571.571,0,0,0-.565.468c-.042.221-.085.448-.127.671a.575.575,0,0,0,.537.681l.624.031-1.725,9.853c-.011.065-.042.1-.105.105l-.7.038a.569.569,0,0,0-.534.468" transform="translate(-202.058 -1.104)" fill="#878787" fillRule="evenodd" />
  </svg>

);

export const SvgUnderline = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14.497 16.489">
    <path id="Tracé_10695" data-name="Tracé 10695" d="M141.872,12.236A6.841,6.841,0,0,0,143.4,7.443l.042-5.621.459-.028a.574.574,0,0,0,.539-.565c0-.215.008-.432.011-.644a.552.552,0,0,0-.165-.413A.555.555,0,0,0,143.88,0h-4.068a.575.575,0,0,0-.575.566c0,.207-.007.417-.01.626a.576.576,0,0,0,.559.584l.885.025c.042.021.082.042.083.083.041,1.694.042,4.192.042,5.886a5.037,5.037,0,0,1-.7,3.04c-.9,1.044-4.866,1.044-5.765,0a5.042,5.042,0,0,1-.7-3.04c0-1.694,0-4.192.041-5.886,0-.042.041-.062.083-.083l.886-.025a.575.575,0,0,0,.558-.584c0-.209-.006-.42-.01-.626A.576.576,0,0,0,134.606,0h-4.068a.556.556,0,0,0-.41.172.555.555,0,0,0-.165.413c0,.213.008.429.01.644a.575.575,0,0,0,.539.566l.459.028.042,5.621a6.853,6.853,0,0,0,1.532,4.793c1.9,1.938,7.424,1.938,9.326,0m-11.319,4.253h13.332a.576.576,0,0,0,.575-.575V15.3a.576.576,0,0,0-.575-.574H130.553a.576.576,0,0,0-.575.574v.61a.576.576,0,0,0,.575.575" transform="translate(-129.963)" fill="#878787" fillRule="evenodd" />
  </svg>

);

export const SvgOrdered = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19.003 17.064">
    <path id="Tracé_10698" data-name="Tracé 10698" d="M84.737,16.2a.428.428,0,0,0,.426-.427v-1.42a.428.428,0,0,0-.426-.426H71.906a.428.428,0,0,0-.426.426v1.42a.428.428,0,0,0,.426.427ZM68.544,0V4.015h-.759V.665L66.742,1.7l-.452-.49L67.586,0Zm.716,9.921v.6H66.227V9.707a1.149,1.149,0,0,1,.228-.842A2.451,2.451,0,0,1,67.538,8.5a2.609,2.609,0,0,0,.839-.23c.087-.07.13-.25.13-.54s-.043-.454-.13-.528a1.094,1.094,0,0,0-.622-.112,1.126,1.126,0,0,0-.638.113c-.089.075-.132.258-.133.548l0,.17h-.743V7.757A1.3,1.3,0,0,1,66.55,6.74a1.985,1.985,0,0,1,1.209-.261,1.918,1.918,0,0,1,1.189.265A1.283,1.283,0,0,1,69.26,7.75a1.343,1.343,0,0,1-.239.936,2.3,2.3,0,0,1-1.1.4A2.855,2.855,0,0,0,67.1,9.3q-.114.091-.114.513v.109Zm-1.824,5.355v-.591h.13a2.214,2.214,0,0,0,.779-.077q.139-.075.139-.429c0-.263-.043-.424-.125-.485A1.376,1.376,0,0,0,67.7,13.6a1.148,1.148,0,0,0-.612.1c-.084.066-.126.232-.126.492l0,.117h-.734v-.149a1.158,1.158,0,0,1,.3-.938A2.01,2.01,0,0,1,67.7,12.99a2.319,2.319,0,0,1,1.21.222.929.929,0,0,1,.33.827q0,.83-.665.934v.02a.764.764,0,0,1,.725.877,1.144,1.144,0,0,1-.321.952,3.283,3.283,0,0,1-2.488,0,1.09,1.09,0,0,1-.327-.923l0-.224h.744c.005.089.008.14.008.159,0,.275.047.448.14.515a1.436,1.436,0,0,0,.7.1,1.2,1.2,0,0,0,.652-.111c.091-.075.136-.254.136-.535a.548.548,0,0,0-.124-.426.9.9,0,0,0-.519-.1h-.464ZM85.164,9.242V7.822a.428.428,0,0,0-.426-.426H71.906a.429.429,0,0,0-.427.426v1.42a.428.428,0,0,0,.426.427H84.737a.428.428,0,0,0,.426-.427m0-6.533V1.288a.428.428,0,0,0-.426-.427H71.906a.429.429,0,0,0-.427.427v1.42a.428.428,0,0,0,.426.426H84.737a.428.428,0,0,0,.426-.426" transform="translate(-66.161)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgUnordered = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19.438 16.881">
    <path id="Tracé_10697" data-name="Tracé 10697" d="M19.011,16.246a.428.428,0,0,0,.427-.427V14.4a.428.428,0,0,0-.427-.426H6.18a.428.428,0,0,0-.427.426v1.42a.429.429,0,0,0,.427.427Zm-17.1.77A1.907,1.907,0,1,0,0,15.11a1.907,1.907,0,0,0,1.907,1.907m0-6.534A1.907,1.907,0,1,0,0,8.576a1.906,1.906,0,0,0,1.907,1.907m0-6.533A1.907,1.907,0,1,0,0,2.042,1.907,1.907,0,0,0,1.907,3.949M19.438,9.286V7.865a.428.428,0,0,0-.427-.426H6.18a.428.428,0,0,0-.427.426v1.42a.429.429,0,0,0,.427.427H19.011a.428.428,0,0,0,.427-.427m0-6.533V1.332A.428.428,0,0,0,19.011.9H6.18a.429.429,0,0,0-.427.427v1.42a.428.428,0,0,0,.427.426H19.011a.428.428,0,0,0,.427-.426" transform="translate(0 -0.135)" fill="#878787" fillRule="evenodd" />
  </svg>
);

export const SvgClose = () => (
  <svg data-name="Composant 71 – 4" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 40">
    <g data-name="Groupe 8714">
      <path data-name="Tracé 9994" d="m-12233.722 21520-22.282 22.281" transform="translate(12269 -21512)" style={{ fill: 'none', stroke: '#707070', strokeWidth: '3px' }} />
      <path data-name="Tracé 9995" d="m-12233.722 21520-22.282 22.281" transform="rotate(-90 -16866.364 4640.64)" style={{ fill: 'none', stroke: '#707070', strokeWidth: '3px' }} />
    </g>
  </svg>

);

export const SvgLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.484" height="16.487" viewBox="0 0 16.484 16.487">
    <path id="Icon_metro-link" data-name="Icon metro-link" d="M9.994,12.574a.855.855,0,0,1-.606-.251,4.028,4.028,0,0,1,0-5.689l3.166-3.166a4.023,4.023,0,1,1,5.689,5.689L16.8,10.6a.857.857,0,1,1-1.212-1.212L17.03,7.945A2.308,2.308,0,0,0,13.766,4.68L10.6,7.846a2.311,2.311,0,0,0,0,3.264.857.857,0,0,1-.606,1.464Zm-3.038,6.2a4.023,4.023,0,0,1-2.845-6.868l1.447-1.447a.857.857,0,0,1,1.212,1.212L5.325,13.122a2.308,2.308,0,0,0,3.264,3.264l3.166-3.166a2.311,2.311,0,0,0,0-3.264.857.857,0,1,1,1.212-1.212,4.028,4.028,0,0,1,0,5.689L9.8,17.6a4,4,0,0,1-2.845,1.178Z" transform="translate(-2.936 -2.29)" fill="#878787" />
  </svg>
);
