import { Popconfirm } from 'antd';
import {
  SvgEdit, SvgLike, SvgReply, SvgShare, SvgTrash, SvgWarning,
} from 'components/Svgs/exchangesSvgs';
import SC from './style';

interface HoverToolsProps {
  handleClick: any
  hasFullAccess: boolean
  mode?: string
  setWaitActionInTools: any
}

const HoverTools: React.FC<HoverToolsProps> = ({
  handleClick, hasFullAccess, mode, setWaitActionInTools,
}) => (
  <SC.HoverTools>
    {mode !== 'reply' && (
      <SC.ButtonTool
        title="Répondre dans le fil de discussion"
        type="button"
        onClick={(e) => handleClick(e, 'reply')}
        padding="0.6vh"
      >
        <SvgReply />
      </SC.ButtonTool>
    )}
    <SC.ButtonTool title={'Ajouter un "j\'aime"'} type="button" onClick={(e) => handleClick(e, 'emoji')} padding="0.6vh">
      <SvgLike />
    </SC.ButtonTool>
    <SC.ButtonTool title="Copier le lien" type="button" onClick={(e) => handleClick(e, 'share')} padding="0.9vh">
      <SvgShare />
    </SC.ButtonTool>
    {hasFullAccess && (
      <SC.ButtonTool title="Modifier le message" type="button" onClick={(e) => handleClick(e, 'edit')} padding="0.9vh">
        <SvgEdit />
      </SC.ButtonTool>
    )}
    {hasFullAccess ? (
      <Popconfirm
        onVisibleChange={(a) => setWaitActionInTools(a)}
        placement="topRight"
        title={(
          <p>
            Voulez-vous vraiment supprimer ce message ?
            <br />
            {' '}
            Cette opération est irréversible.
          </p>
)}
        onConfirm={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e, 'trash');
        }}
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        okText="Supprimer"
        cancelText="Retour"
      >
        <SC.ButtonTool
          onClick={(e) => {
            e.stopPropagation();
          }}
          type="button"
          title="Supprimer le message"
          padding="0.9vh"
        >
          <SvgTrash />
        </SC.ButtonTool>
      </Popconfirm>
    ) : (
      <SC.ButtonTool title="Signaler le message" type="button" onClick={(e) => handleClick(e, 'warning')}>
        <SvgWarning />
      </SC.ButtonTool>
    )}
  </SC.HoverTools>
);

export default HoverTools;
