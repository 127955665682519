import Image from 'next/image';
import { SvgEchangeDisabled } from 'components/Svgs';
import SC from './style';

interface NotAuthorizedProps {

}

const NotAuthorized: React.FC<NotAuthorizedProps> = () => (
  <div css={SC.echangerWrap}>
    <Image src="/vitre-fake-flou2.png" alt="virte fake echanger" fill sizes="100vw" />
    <p css={SC.text1}>Retrouvez ici plusieurs centaines d'orthophonistes, logopèdes et logopédistes échangent et apprennent des uns des autres autour de leur profession</p>
    <div css={SC.SvgPlaceholder}>
      <SvgEchangeDisabled />
    </div>
    <p css={SC.text2}>
      Pour accéder à cet espace sur-mesure, co-construit avec des orthophonistes, connectez-vous à l'Happyneuron Academy (réservée aux orthophonistes)"
    </p>
  </div>
);

export default NotAuthorized;
