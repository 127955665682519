import { graphqlOperation, withSSRContext } from 'aws-amplify';
import { notification } from 'antd';
import {
  EditMessageMutationVariables, LikeMessageMutationVariables,
  MessageMutationVariables, UnlikeMessageMutationVariables,
  ListReplyByMessageQueryVariables, ReplyMutationVariables,
  ListMessageByChannelQueryVariables, DeleteMessageMutationVariables,
  OnReplyByMessageSubscriptionVariables, OnEditReplyByMessageSubscriptionVariables,
  EditReplyMutationVariables, DeleteReplyMutationVariables,
  OnDeleteReplyByMessageSubscriptionVariables, EditProfileMutationVariables, LastUserReadMutationVariables,
} from 'API';
import {
  deleteMessage, deleteReply, editMessage, editProfile,
  editReply, lastUserRead, likeMessage, message, profile, reply, unlikeMessage,
} from 'graphql/mutations';
import {
  getProfile, listChannels, listMessageByChannel, listReplyByMessage,
} from 'graphql/queries';
import {
  onDeleteMessageByChannel, onDeleteReplyByMessage, onEditMessageByChannel,
  onEditReplyByMessage, onLikeMessageByChannel, onReplyByChannel,
  onReplyByMessage, onUnLikeMessageByChannel, onMessage,
} from 'graphql/subscriptions';

const { API } = withSSRContext();

// QUERIES

const requestAppSync = (callback) => {
  try {
    return callback;
  } catch (error) {
    console.log({ error });
    notification.error({
      message: 'Veuillez nous excuser, une erreur est survenue.',
      description: 'Essayez de recharger votre page ou contactez le support si le problème persiste',
    });
    throw new Error(error);
  }
};

export const queryListMessageByChannel = (variables: ListMessageByChannelQueryVariables) => requestAppSync(API.graphql(
  graphqlOperation(listMessageByChannel, variables),
));

export const queryListReplyByMessage = (variables: ListReplyByMessageQueryVariables) => requestAppSync(API.graphql(
  graphqlOperation(listReplyByMessage, variables),
));

export const queryProfile = ({ name, role, picture }) => requestAppSync(API.graphql(
  graphqlOperation(
    profile, {
      newProfile: {
        name,
        role,
        picture,
      },
    },
  ),
));

export const queryGetProfile = () => requestAppSync(API.graphql(graphqlOperation(getProfile)));

export const queryListChannels = () => requestAppSync(API.graphql(graphqlOperation(listChannels)));

// MUTATIONS

export const mutationDeleteMessage = (variables: DeleteMessageMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(
    deleteMessage,
    variables,
  ),
));

export const mutationDeleteReply = (variables: DeleteReplyMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(
    deleteReply,
    variables,
  ),
));

export const mutationMessage = (variables: MessageMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(message, variables),
));

export const mutationEditMessage = (variables: EditMessageMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(editMessage, variables),
));
export const mutationEditReply = (variables: EditReplyMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(editReply, variables),
));

export const mutationLikeMessage = (variables: LikeMessageMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(likeMessage, variables),
));

export const mutationUnLikeMessage = (variables: UnlikeMessageMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(unlikeMessage, variables),
));

export const mutationReply = (variables: ReplyMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(reply, variables),
));

export const mutationEditProfile = (variables: EditProfileMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(editProfile, variables),
));

export const mutationLastUserRead = (variables: LastUserReadMutationVariables) => requestAppSync(API.graphql(
  graphqlOperation(lastUserRead, variables),
));

// SUBSCRIPTIONS

export const subscribeOnReply = (inReplyToMessage: OnReplyByMessageSubscriptionVariables, onNext, onError) => API.graphql(
  graphqlOperation(onReplyByMessage, { inReplyToMessage }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnEditReply = (inReplyToMessage: OnEditReplyByMessageSubscriptionVariables,
  onNext, onError) => API.graphql(
  graphqlOperation(onEditReplyByMessage, { inReplyToMessage }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnDeleteReply = (inReplyToMessage: OnDeleteReplyByMessageSubscriptionVariables,
  onNext, onError) => API.graphql(
  graphqlOperation(onDeleteReplyByMessage, { inReplyToMessage }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnMessage = (onNext, onError) => API.graphql(
  graphqlOperation(onMessage),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnEditMessage = (channelId, onNext, onError) => API.graphql(
  graphqlOperation(onEditMessageByChannel, { channelId }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnDeleteMessage = (channelId, onNext, onError) => API.graphql(
  graphqlOperation(onDeleteMessageByChannel, { channelId }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnLikeMessage = (channelId, onNext, onError) => API.graphql(
  graphqlOperation(onLikeMessageByChannel, { channelId }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnUnLikeMessage = (channelId, onNext, onError) => API.graphql(
  graphqlOperation(onUnLikeMessageByChannel, { channelId }),
).subscribe({
  next: onNext,
  error: onError,
});

export const subscribeOnReplyByChannel = (channelId, onNext, onError) => API.graphql(
  graphqlOperation(onReplyByChannel, { channelId }),
).subscribe({
  next: onNext,
  error: onError,
});
