/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const profile = /* GraphQL */ `
  mutation Profile($newProfile: ProfileInput!) {
    profile(newProfile: $newProfile) {
      id
      name
      email
      picture
      role
      privacy
      createdAt
      updatedAt
      likesCount
    }
  }
`;
export const editProfile = /* GraphQL */ `
  mutation EditProfile($newProfile: ProfileInput!) {
    editProfile(newProfile: $newProfile) {
      id
      name
      email
      picture
      role
      privacy
      createdAt
      updatedAt
      likesCount
    }
  }
`;
export const channel = /* GraphQL */ `
  mutation Channel($name: String!, $category: String!, $premium: Boolean!) {
    channel(name: $name, category: $category, premium: $premium) {
      id
      categoryName
      name
      type
      category
      premium
      createdAt
      messageCount
      userRead {
        lastUserRead
        unreadUserMessage
      }
      trainingHnaId
    }
  }
`;
export const editChannel = /* GraphQL */ `
  mutation EditChannel(
    $id: ID!
    $name: String!
    $category: String!
    $premium: Boolean!
  ) {
    editChannel(id: $id, name: $name, category: $category, premium: $premium) {
      id
      categoryName
      name
      type
      category
      premium
      createdAt
      messageCount
      userRead {
        lastUserRead
        unreadUserMessage
      }
      trainingHnaId
    }
  }
`;
export const message = /* GraphQL */ `
  mutation Message($channelId: ID!, $content: String!) {
    message(channelId: $channelId, content: $content) {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      replies
      type
      likesCount
      liked
    }
  }
`;
export const editMessage = /* GraphQL */ `
  mutation EditMessage($id: ID!, $channelId: ID!, $content: String!) {
    editMessage(id: $id, channelId: $channelId, content: $content) {
      id
      channelId
      content
      createdAt
      updatedAt
      replies
      type
      likesCount
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($id: ID!, $channelId: ID!) {
    deleteMessage(id: $id, channelId: $channelId) {
      id
      channelId
      status
    }
  }
`;
export const reply = /* GraphQL */ `
  mutation Reply($messageId: ID!, $channelId: ID!, $content: String!) {
    reply(messageId: $messageId, channelId: $channelId, content: $content) {
      id
      channelId
      content
      createdAt
      updatedAt
      profile {
        id
        name
        email
        picture
        role
        privacy
        createdAt
        updatedAt
        likesCount
      }
      inReplyToMessage
      type
      likesCount
      liked
    }
  }
`;
export const editReply = /* GraphQL */ `
  mutation EditReply($id: ID!, $channelId: ID!, $content: String!) {
    editReply(id: $id, channelId: $channelId, content: $content) {
      id
      channelId
      content
      createdAt
      updatedAt
      inReplyToMessage
      type
      likesCount
    }
  }
`;
export const deleteReply = /* GraphQL */ `
  mutation DeleteReply($id: ID!, $messageId: ID!, $channelId: ID!) {
    deleteReply(id: $id, messageId: $messageId, channelId: $channelId) {
      id
      channelId
      inReplyToMessage
      status
    }
  }
`;
export const likeMessage = /* GraphQL */ `
  mutation LikeMessage($messageId: ID!, $channelId: ID!) {
    likeMessage(messageId: $messageId, channelId: $channelId) {
      id
      channelId
      inReplyToMessage
      likesCount
      status
    }
  }
`;
export const unlikeMessage = /* GraphQL */ `
  mutation UnlikeMessage($messageId: ID!, $channelId: ID!) {
    unlikeMessage(messageId: $messageId, channelId: $channelId) {
      id
      channelId
      inReplyToMessage
      likesCount
      status
    }
  }
`;
export const lastUserRead = /* GraphQL */ `
  mutation LastUserRead($messageId: ID!, $channelId: ID!) {
    lastUserRead(messageId: $messageId, channelId: $channelId) {
      channelId
      userId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const notifyLiked = /* GraphQL */ `
  mutation NotifyLiked(
    $id: ID!
    $userId: ID!
    $channelId: ID!
    $messageId: ID!
    $likedBy: ID!
  ) {
    notifyLiked(
      id: $id
      userId: $userId
      channelId: $channelId
      messageId: $messageId
      likedBy: $likedBy
    ) {
      ... on Liked {
        id
        type
        userId
        channelId
        messageId
        likedBy
        createdAt
      }
      ... on Replied {
        id
        type
        userId
        channelId
        messageId
        replyId
        repliedBy
        createdAt
      }
    }
  }
`;
export const notifyReplied = /* GraphQL */ `
  mutation NotifyReplied(
    $id: ID!
    $userId: ID!
    $channelId: ID!
    $messageId: ID!
    $replyId: ID!
    $repliedBy: ID!
  ) {
    notifyReplied(
      id: $id
      userId: $userId
      channelId: $channelId
      messageId: $messageId
      replyId: $replyId
      repliedBy: $repliedBy
    ) {
      ... on Liked {
        id
        type
        userId
        channelId
        messageId
        likedBy
        createdAt
      }
      ... on Replied {
        id
        type
        userId
        channelId
        messageId
        replyId
        repliedBy
        createdAt
      }
    }
  }
`;
