import { css } from 'styled-components';

const echangerWrap = css`
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(to bottom, var(--color-light-royal-blue), #17007d 38%, #17007d);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SvgPlaceholder = css`
  position: relative;
  width: 21.52vw;
  margin: 4vh 0;
`;

const text1 = css`
  font-family: SourceSansPro;
  font-size: var(--size-34);
  font-weight: bold;
  line-height: 1.24;
  text-align: center;
  color: #fff;
  width: 37vw;
  margin-top: 5vh;
  z-index: 10;
`;

const text2 = css`
  font-family: SourceSansPro;
  font-size: var(--size-27);
  line-height: 1.22;
  text-align: center;
  color: #fff;
  width: 37vw;
  z-index: 10;
`;

export default {
  echangerWrap,
  SvgPlaceholder,
  text1,
  text2,
};
