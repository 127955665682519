import {
  convertToRaw, Modifier, SelectionState, EditorState,
} from 'draft-js';

export const regexpEmail = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;

export const getCurrentBlock = (editorState) => {
  const currentSelection = editorState.getSelection();
  const blockKey = currentSelection.getStartKey();
  return (editorState.getCurrentContent().getBlockForKey(blockKey));
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        (entityKey !== null
        && contentState.getEntity(entityKey).getType() === 'LINK')
      );
    },
    callback,
  );
};

export const findPreviewEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        (entityKey !== null
        && contentState.getEntity(entityKey).getType() === 'LINK')
      );
    },
    callback,
  );
};

export const getLinkKeyInfos = (editorState) => {
  let link = '';
  const contentState = editorState.getCurrentContent();

  const end = editorState.getSelection().getEndOffset();
  const slicedText = getCurrentBlock(editorState).getText().slice(0, end);
  let start = slicedText.lastIndexOf(' ');
  if (start === -1) { start = 0; }
  if (start !== 0) start += 1;
  const text = slicedText.slice(start);
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
  const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
  if (linkKey) {
    const linkInstance = contentState.getEntity(linkKey);
    link = linkInstance.getData().url;
  }

  return { link, text };
};

export const formatMessageBlocks = (editorState) => {
  const raw = convertToRaw(editorState.getCurrentContent());
  const newMessageFormatted = raw?.blocks
    .filter((block, i) => !(block.type !== 'MyCustomBlock' && !block.text && !raw?.blocks[i - 1]?.text));
  return { newMessageFormatted, raw };
};

export const replaceTextEntity = (editorState, entitySelection, urlValue, entityKey) => Modifier.replaceText(
  editorState.getCurrentContent(),
  entitySelection,
  urlValue.text,
  editorState.getCurrentInlineStyle(),
  entityKey,
);

export const selectionURLInfosFormatted = ({ blockKey, start, end }) => ({
  anchorKey: blockKey,
  focusKey: blockKey,
  anchorOffset: start,
  focusOffset: end,
});

export const styleMap = {
  edited: {
    color: 'var(--color-gray-warm)',
    fontSize: 'var(--size-16)',
  },
};

export const getLastKeyBeforePreview = (blockMap) => {
  let lastGoodKey = blockMap.last().getKey();
  const arrayBlock = Object.entries(blockMap.toJS());
  arrayBlock.forEach?.(([, b]: any, key) => {
    if (b.type === 'MyCustomBlock') lastGoodKey = arrayBlock[key - 1]?.[0];
  });
  return lastGoodKey;
};

export const moveSelectionToEnd = (editorState) => {
  const currentContent = editorState.getCurrentContent();

  const blockMap = currentContent.getBlockMap();
  const lastGoodKey = getLastKeyBeforePreview(blockMap);

  const lastBlockWithoutPreview = currentContent.getBlockForKey(lastGoodKey);

  const length = lastBlockWithoutPreview.getLength();

  const selection = new SelectionState({
    anchorKey: lastGoodKey,
    anchorOffset: length,
    focusKey: lastGoodKey,
    focusOffset: length,
  });
  const newEditorState = EditorState.moveSelectionToEnd(editorState);
  return EditorState.forceSelection(newEditorState, selection);
};
