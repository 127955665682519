import { forwardRef } from 'react';
import { SvgClose } from 'components/Svgs';
import SC from './style';
import { IUrlEditChoice } from 'components/Exchange/types';

// eslint-disable-next-line react/display-name
const UrlInput = forwardRef(({
  confirmLink, setUrlValue, urlValue,
}: any, urlRef: any) => {
  const handleSubmit = (e, choice: IUrlEditChoice) => {
    e.preventDefault();
    confirmLink(e, choice, true);
  };

  const handleChange = ({ target: { name, value } }) => {
    setUrlValue((pS) => ({ ...pS, [name]: value }));
  };

  return (
    <div css={SC.urlInputContainer}>
      <p css={SC.title}>
        Ajouter un lien
        <button
          css={SC.buttonClose}
          type="button"
          onClick={(e) => handleSubmit(e, 'back')}
        >
          <SvgClose />
        </button>

      </p>
      <div>
        <label css={SC.label} htmlFor="text">
          <span>Texte</span>
          <input css={SC.input} name="text" type="text" value={urlValue?.text} onChange={handleChange} />
        </label>
      </div>
      <div>

        <label css={SC.label} htmlFor="link">
          <span>Lien</span>
          <input css={SC.input} ref={urlRef} name="link" type="link" value={urlValue?.link} onChange={handleChange} />
        </label>
      </div>
      <div>
        <button css={SC.buttonSave} type="button" onClick={(e) => handleSubmit(e, 'edit')}>Sauvegarder</button>
        <button css={SC.buttonBack} type="button" onClick={(e) => handleSubmit(e, 'back')}>Annuler</button>
      </div>
    </div>
  );
});

export default UrlInput;
